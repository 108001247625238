
import React from 'react';
import NavigationItem from './NavigationItem/NavigationItem';
import '../../styles/sidebar.css';
import '../../styles/header.css';
import { FaSignOutAlt } from "react-icons/fa";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import { IoIosContacts, IoIosStats } from "react-icons/io";
import InputIcon from '@material-ui/icons/Input';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BarChartIcon from '@material-ui/icons/BarChart';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
const drawerWidth = 270;
const useStyles = makeStyles((theme) => ({
  root: {
  }, appBar: {
    top: 0,
    left: -6,
    width: 78,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: 0,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
export default function NavigationItems(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar   >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            <div className="sidebar-header text-center" >
              <h1>Menu</h1>
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}>
        <div className={classes.toolbar}>
          <center> <h1 style={{ marginLeft: 21 }}><strong>Cosa</strong>Finance</h1></center>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <nav id="sidebar">
          <ul className="components">
            <React.Fragment>
              <NavigationItem link="/devis">  <IoIosStats style={{ marginRight: '17px' }} size={20} /> Devis</NavigationItem>
            </React.Fragment>
          </ul>
          <ul className="components">
            <React.Fragment>
              <NavigationItem link="/contact">  <IoIosContacts style={{ marginRight: '17px' }} size={20} /> Contacts facturation</NavigationItem>
            </React.Fragment>
          </ul>
          <ul className="components">
            <React.Fragment>
              <NavigationItem link="/inscription">  <InputIcon style={{ marginRight: '17px' }} size={20} /> Inscription</NavigationItem>
            </React.Fragment>
          </ul>
          <ul className="components">
            <React.Fragment>
              <NavigationItem link="/demande">  <PersonAddIcon style={{ marginRight: '17px' }} size={20} /> Demande</NavigationItem>
            </React.Fragment>
          </ul>
          <ul className="components">
            <React.Fragment>
              <NavigationItem link="/facture">  <ReceiptIcon style={{ marginRight: '17px' }} size={20} /> L'encaisseur </NavigationItem>
            </React.Fragment>
          </ul>
          <ul className="components">
            <React.Fragment>
              <NavigationItem link="/Objectifs">  <EmojiObjectsIcon style={{ marginRight: '17px' }} size={20} /> Objectif </NavigationItem>
            </React.Fragment>
          </ul>
          <ul className="components">
            <React.Fragment>
              <NavigationItem link="/Statistique">  <BarChartIcon style={{ marginRight: '17px' }} size={20} /> Statistique </NavigationItem>
            </React.Fragment>
          </ul>
          <Divider />
          <ul className="components">
            <React.Fragment>
              <NavigationItem link="/logout">  <FaSignOutAlt style={{ marginRight: '17px' }} size={20} /> Logout</NavigationItem>
            </React.Fragment>
          </ul>
        </nav>
      </Drawer>
    </div>
  )
}
