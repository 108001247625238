import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const fetchContactSuccess = (contact) => {
    return {
        type: actionTypes.FETCH_CONTACT_SUCCESS,
        contact: contact
    };
};
export const fetchContactFail = (error) => {
    return {
        type: actionTypes.FETCH_CONTACT_FAIL,
        error: error
    };
};
export const fetchContactStart = () => {
    return {
        type: actionTypes.FETCH_CONTACT_START
    };
};
export const fetchContact = (token) => {
    return dispatch => {
        dispatch(fetchContactStart());
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};
        axios.get(`${BACK_BASE_URL}/api/users?roles=ROLE_USER&nature=local&isActive=true`)
            .then(res => {
                const fecthedContact = [];
                    for (const key in res.data['hydra:member']) {
                        fecthedContact.push({
                            ...res.data['hydra:member'][key]
                        });
                    }
              dispatch(fetchContactSuccess(fecthedContact));
            })
            .catch(err => {
                if (err.response.status === 401) {
                    toastr.warning(err.response.data.message, 'Sign in again');
                    dispatch(logout());
                }
                dispatch(fetchContactFail(err));
            })
    }
}
export const setContactSuccess = (contact, contacts) => {
    return {
        type: actionTypes.SET_CONTACT_SUCCESS,
        contact: contact,
        contacts: contacts
    };
};
export const setContactFail = (error) => {
    return {
        type: actionTypes.SET_CONTACT_FAIL,
        error: error
    };
};
export const setContactStart = () => {
    return {
        type: actionTypes.SET_CONTACT_START
    };
};
export const setContact = (token, ContactId, contactBody, contactsData) => {
    return dispatch => {
        dispatch(setContactStart());
        const headerConfig = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.put(`${BACK_BASE_URL}/api/users/${ContactId}`, contactBody, headerConfig)
            .then(res => {
                if (res.status === 200) {
                    const updatedContact = res.data;
                    dispatch(setContactSuccess(contactsData, updatedContact));
                    toastr.success('Succès', 'Vos données sont bien mises à jour');
                }
            })
            .catch(err => {
                if (err.response.status === 401) {

                    toastr.warning(err.response.data.message, 'Please sign in again');
                    dispatch(logout());
                }
                dispatch(fetchContactFail(err));
            })
    }
}
