import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import {reducer as toastrReducer} from 'react-redux-toastr'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import authReducer from './store/reducers/auth';
import userReducer from './store/reducers/user';
import reducerPro from './store/reducers/devis';
import reducerCon from './store/reducers/contacts';
import reducerProd from './store/reducers/product';
import * as Sentry from "@sentry/react";
import reducerDet from './store/reducers/detail';
import reducerdem from './store/reducers/demande';
import reducerEntre from './store/reducers/entreprise';
import reducerFact from './store/reducers/factureclient';
import reducerobj from './store/reducers/objectifs';
import reducerSmart from './store/reducers/smart';
import reducerSell from './store/reducers/sellsy';
import reducerForgetPwd from './store/reducers/forgetPassword';
import reducerNewPwd from './store/reducers/newPassword';
import reducerStaff from './store/reducers/staff';
const SENTRY_FRONT_URL = process.env.REACT_APP_SENTRY_URL;

Sentry.init({dsn: SENTRY_FRONT_URL});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  toastr: toastrReducer,
  devi: reducerPro,
  contacts: reducerCon,
  products:reducerProd,
  details:reducerDet,
  demande: reducerdem,
  entreprise: reducerEntre,
  facture: reducerFact,
  objectif: reducerobj,
  smart:reducerSmart,
  sellsy: reducerSell,
  forgetEmail: reducerForgetPwd,
  password: reducerNewPwd,
  staff: reducerStaff,
});
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));
const app = (
  <>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </>
)
ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
