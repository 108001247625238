import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const fetchEntrepriseSuccess = (entreprise) => {
    return {
        type: actionTypes.FETCH_ENTREPRISE_SUCCESS,
        entreprise: entreprise
    };
};
export const fetchEntrepriseFail = (error) => {
    return {
        type: actionTypes.FETCH_ENTREPRISE_FAIL,
        error: error
    };
};
export const fetchEntrepriseStart = () => {
    return {
        type: actionTypes.FETCH_ENTREPRISE_START
    };
};
export const fetchEntreprise = (token) => {
    return dispatch => {
        dispatch(fetchEntrepriseStart());
        const headerConfig = {
            headers: { Authorization: `Bearer ${token}` }
          };
        axios.get(`${BACK_BASE_URL}/api/entreprises?sellsy=true`, headerConfig)
            .then(res => {
                const fetchedEntreprises = [];
                for (const key in res.data['hydra:member']) {
                  fetchedEntreprises.push({
                    ...res.data['hydra:member'][key],
                  });
                }
              dispatch(fetchEntrepriseSuccess(fetchedEntreprises));
            })
            .catch(err => {
                if (err.response.status === 401) {
                    toastr.warning(err.response.data.message, 'Sign in again');
                    dispatch(logout());
                }
                dispatch(fetchEntrepriseFail(err));
            })
    }
}
