import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    entreprise: [],
    loading: false
}
const fetchEntrepriseStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchEntrepriseSuccess = (state, action) => {
    return updateObject(state, {
        entreprise: action.entreprise,
        loading: false
    });
}
const fecthEntrepriseFail = (state, action) => {
    return updateObject(state, { loading: false });
}
const reducerDet = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ENTREPRISE_START: return fetchEntrepriseStart(state, action);
        case actionTypes.FETCH_ENTREPRISE_SUCCESS: return fetchEntrepriseSuccess(state, action);
        case actionTypes.FETCH_ENTREPRISE_FAIL: return fecthEntrepriseFail(state, action);
        default: return state;
    }
}
export default reducerDet;
