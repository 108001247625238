import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const fetchFactureSuccess = (factures) => {
    return {
        type: actionTypes.FETCH_FACTURECLIENT_SUCCESS,
        factures: factures
    };
};
export const fetchFactureFail = (error) => {
    return {
        type: actionTypes.FETCH_FACTURECLIENT_FAIL,
        error: error
    };
};
export const fetchFactureStart = () => {
    return {
        type: actionTypes.FETCH_FACTURECLIENT_START
    };
};
export const fetchFacture = (token) => {
    return dispatch => {
        dispatch(fetchFactureStart());
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
        axios.get(`${BACK_BASE_URL}/factureClient?isActive=true`)
            .then(res => {
                const fecthedFacture = [];
                for (const key in res.data) {
                    fecthedFacture.push({
                        ...res.data[key],
                        counter: res.data[key].id,
                    });
                }
                dispatch(fetchFactureSuccess(fecthedFacture));
            })
            .catch(err => {
                if (err.response.status === 401) {
                    toastr.warning(err.response.data.message, 'Sign in again');
                    dispatch(logout());
                }
                dispatch(fetchFactureFail(err));
            })
    }
}
export const setFactureSuccess = (facture, factures) => {
    return {
        type: actionTypes.SET_FACTURECLIENT_SUCCESS,
        facture: facture,
        factures: factures
    };
};
export const setFactureFail = (error) => {
    return {
        type: actionTypes.SET_FACTURECLIENT_FAIL,
        error: error
    };
};
export const setFactureStart = () => {
    return {
        type: actionTypes.SET_FACTURECLIENT_START
    };
};
export const setFacture = (token, FactureId, updateData, FactureOutsideStore) => {
    return dispatch => {
        dispatch(setFactureStart());
        const headerConfig = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.put(`${BACK_BASE_URL}/api/suiv_factures/${FactureId}`, updateData, headerConfig)
            .then(res => {
                if (res.status === 200) {
                    const updatedFacture = res.data;
                    dispatch(setFactureSuccess(updatedFacture, FactureOutsideStore));
                    toastr.success('Succès', 'Votre données a bien été mis à jour');
                   
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    toastr.warning(err.response.data.message, 'Please sign in again');
                    dispatch(logout());
                }
                dispatch(fetchFactureFail(err));
            })
    }
}
