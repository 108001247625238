import * as actionTypes from './actions-types';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};
export const authSuccess = (token) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token
  };
};
export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};
export const logout = () => {
  localStorage.removeItem('token');
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};
export const checkAuthTimeOut = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime);
  };
};
export const auth = (email, password) => {
  return dispatch => {
    dispatch(authStart());
    const authData = {
      email: email,
      password: password
    }
    
    axios.post(`${BACK_BASE_URL}/authentication_token`, authData)
      .then(res => {
        if (res.status === 200) {
          localStorage.setItem('token', res.data.token);
          const decodedData = jwtDecode(res.data.token);
          const expirationDate = new Date().getTime() + decodedData.exp * 1000;
          localStorage.setItem('expirationDate', new Date(expirationDate));
          dispatch(authSuccess(res.data.token));
          dispatch(checkAuthTimeOut(decodedData.exp)); 
        }
      })
      .catch(err => {
        if (err.response.status === 401) {
          toastr.error('Failed to login', err.response.data.message);
          dispatch(authFail(err.response.data));
        }
      }
      );
  };
}
export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path
  }
}
export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeOut((expirationDate.getTime() - new Date().getTime()) / 1000));
      }
    }
  }
}
