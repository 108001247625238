import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { updateObject, checkValidity } from '../../shared/utility';
import * as actions from '../../store/actions/index';
import HashLoader from "react-spinners/HashLoader";
import { css } from "@emotion/core";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: yellow;
`;
class Auth extends Component {
    state = {
        controls: {
            email: {
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                value: '',
                validation: {
                    required: true,
                    minLength: 5
                },
                valid: false,
                touched: false
            }
        }
    }
    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    }
    inputChangeHandler = (event) => {
        const eventValue = event.target.value;
        const inputName = event.target.name;
        const updatedElements = updateObject(this.state.controls, {
            [inputName]: updateObject(this.state.controls[inputName], {
                value: eventValue,
                valid: checkValidity(eventValue, this.state.controls[inputName].validation),
                touched: true
            })
        });
        this.setState({ controls: updatedElements });
    }
    submitHandler = (event) => {
        event.preventDefault();
        this.props.onAuth(
            this.state.controls.email.value,
            this.state.controls.password.value
        );
    }
    disableSubmitHandler = () => {
        return !(this.state.controls.email.valid && this.state.controls.password.valid);
    }
    render() {
        let form = (
            <div>
                <center>
                    <div>
                        <br></br>
                        <h1><strong> Bienvenue à Cosa</strong><strong>Finance</strong><br></br></h1>
                        <p>CosaFinace est l'outil  de gestion d'entreprise de</p><p> <a href="https://cosavostra.com" >CosaVostra</a>.</p>
                        <p> CRM, facturation, comptabilité et support sont intégrés sur une même interface intuitive.
                    </p>
                    Pour toute question, contactez <a href="mailto:laurent@cosavostra.com" >laurent@cosavostra.com</a>
                        <p><span role="img" aria-label="heart">Made with ❤️ by CosaVostra</span></p>
                        <hr class="my-5"></hr>
                    </div>
                </center>
                <center><h3>Connexion</h3></center>
                <div className="form-group">
                    <label>Adresse e-mail</label>
                    <input name="email" type="email" className="form-control" placeholder="Enter email"
                        onChange={(event) => this.inputChangeHandler(event)} />
                    <div style={{ fontSize: 12, color: "red" }}>
                        {!this.state.controls.email.valid && this.state.controls.email.touched ? "invalid Email" : null}
                    </div>
                </div>
                <div className="form-group">
                    <label>Mot de passe</label>
                    <input name="password" type="password" className="form-control" placeholder="Enter password"
                        onChange={this.inputChangeHandler} />
                    <div style={{ fontSize: 12, color: "red" }}>
                        {!this.state.controls.password.valid && this.state.controls.password.touched ? "invalid Password" : null}
                    </div>
                </div>
            </div>
        );
        if (this.props.loading) {
            form =
                <div className="sweet-loading">
                    <br></br><br></br><br></br><br></br> <br></br><br></br><br></br><br></br>
                    <center>
                        <HashLoader
                            css={override}
                            size={150}
                            color={"#000000"}
                            loading={this.state.loading}
                        />
                    </center>
                </div>
        }
        let authRedirect = null;
        if (this.props.isAuthenticated) {
            authRedirect = <Redirect to={this.props.authRedirectAuth} />
        }
        return (
            <div>
                <div className="col-sm-4 container" >
                    {authRedirect}
                    <form onSubmit={this.submitHandler}>
                        {form}
                        <div className="form-group">
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1">Se souvenir de moi</label>
                            </div>
                        </div>
                        <button disabled={this.disableSubmitHandler()} type="submit" className="btn btn-primary btn-lg btn-block" >Se connecter</button>
                        <p className="forgot-password text-right">
                            <br></br>
                            <Link to="/forgetpwd">Mot de passe oublié ?</Link>
                        </p>
                    </form>
                </div>
            </div>
        )
    }
}
const mapStateTpProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectAuth: state.auth.authRedirectPath
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/contact'))
    };
};
export default connect(mapStateTpProps, mapDispatchToProps)(Auth);
