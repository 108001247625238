import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    staff: [],
    loading: false
}
const fetchStaffStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchStaffSuccess = (state, action) => {
    return updateObject(state, {
        staff: action.staff,
        loading: false
    });
}
const fecthStaffFail = (state, action) => {
    return updateObject(state, { loading: false });
}
const reducerStaff = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_STAFF_START: return fetchStaffStart(state, action);
        case actionTypes.FETCH_STAFF_SUCCESS: return fetchStaffSuccess(state, action);
        case actionTypes.FETCH_STAFF_FAIL: return fecthStaffFail(state, action);
        default: return state;
    }
}
export default reducerStaff;
