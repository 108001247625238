import React, { Component } from 'react';
import { Typography, Link } from '@material-ui/core';
class Cosasuccessinscrip extends Component {
  render(props) {
    return (
      <div>
        <div></div>
        <div></div>
        <center><h1><strong>Cosa</strong>Finance <br></br>Made with ❤️ by CosaVostra</h1></center>
        <br></br>
        <center><img src="https://cdn.welcometothejungle.co/uploads/website/cover/3236/149149/cosavostra.jpg" alt="Avatar" /></center>
        <center><p class="serif">L'équipe cosavostra vous remercie pour votre confiance, votre demande a été bien entegistré.. </p></center>
        <center><Typography >
          <Link href="https://www.cosavostra.com/" >
            CosaVostra
          </Link>
        </Typography></center>
      </div >
    )
  }
}
export default Cosasuccessinscrip;
