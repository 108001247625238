import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import MaterialTable from 'material-table';
import { Container } from '@material-ui/core';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
class Devis extends Component {
    constructor(props){
        super(props);
        this.state = {
            page: 0,
            search: '',
        }
        this.columns = [
            { title: 'Check', field: 'idCheck', editable: 'never' },
            { title: 'N°devis', field: 'ident', editable: 'never' },
            { title: 'Status', field: 'statut', editable: 'never' },
            { title: 'Client', field: 'clientDevis', editable: 'never' },
            { title: 'Montant_TTC', field: 'symbole', editable: 'never' },
            { title: 'Facturé_TTC', field: 'totpaye', editable: 'never' },
            { title: 'Reste_à_Facturé_TTC', field: 'totpayee', editable: 'never' },
            { title: 'Date', field: 'date', editable: 'never' },
            { title: '%paiement_facturé', field: 'paiement', editable: 'never' },
            { title: 'commentaire', field: 'comm', editable: 'onUpdate' },
            { title: 'Createur', field: 'createur_de_devis.id',
              lookup: {}
        }
        ]
    }
    componentDidMount() {
        this.props.onFetchDevis(this.props.token, this.state.page, this.state.itemsPerPage, this.state.search);
        this.props.onFetchStaff(this.props.token);
    }
    waitForUpdateDevis(token, idDevis, DataUpdt, data) {
        this.props.onSetDevis(token, idDevis, DataUpdt, data);
    }
    onSearchHandler = (searchText) => {
        if (this.state.page !== 1) {
            this.setState({ page: 0 })
        }
        this.setState({ search: searchText });
        this.props.onFetchDevis(this.props.token, 0,this.state.itemsPerPage, searchText)
    }
    onPageChangeHandler = (event, newPage) => {
        this.setState({ page: newPage })
        this.props.onFetchDevis(this.props.token, newPage, this.state.itemsPerPage, this.state.search);
    }
    onRowsPerPageHandler = (pageSize) => {
        if (this.state.page !== 0) {
            this.setState({ page: 0 })
        }
        this.setState({ itemsPerPage: pageSize })
        this.props.onFetchDevis(this.props.token, 0, pageSize, this.state.search);
    }
    separateurNumber =  (a, b) => {
        a = '' + a;
        b = b || ' ';
        var c = '',
            d = 0;
        while (a.match(/^0[0-9]/)) {
          a = a.substr(1);
        }
        for (var i = a.length-1; i >= 0; i--) {
          c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
          d++;
        }
        return c;
      }
    render() {
        let columns1 = [
            { title: 'Check', field: 'idCheck', editable: 'never', },
            { title: 'N°devis', field: 'ident', editable: 'never' },
            { title: 'Status', field: 'statut', editable: 'never' },
            { title: 'Client', field: 'clientDevis', editable: 'never' },
            { title: 'Montant_TTC', field: 'symbole', editable: 'never' , render : props =>  this.separateurNumber(props.symbole) },
            { title: 'Facturé_TTC', field: 'totpaye', editable: 'never' , render : props =>  this.separateurNumber(props.totpaye) },
            { title: 'Reste_à_Facturé_TTC', field: 'totpayee', editable: 'never' , render : props =>  this.separateurNumber(props.totpayee) },
            { title: 'Date', field: 'date', editable: 'never' },
            { title: '%paiement_facturé', field: 'paiement', editable: 'never' },
            { title: 'commentaire', field: 'comm', editable: 'onUpdate' },
            { title: 'Createur', field: 'createur_de_devis.id',
              lookup: {}
        }
        ]
        const devisTableData = [];
        for (const key in this.props.devis) {
            devisTableData.push({
                idCheck: `Check ${this.props.devis[key].id}` ,
                ...this.props.devis[key]
            });
        }
        const lookupBody = {};
        if(!this.props.loadingstaff){
            for (const key in this.props.staff) {
                lookupBody[this.props.staff[key].id] = this.props.staff[key].fullName;
            }
            columns1[10].lookup = lookupBody;
        }
        const devis = (<MaterialTable
            title="Devis List"
            columns={columns1}
            data={devisTableData}
            onChangePage = {() => {window.scrollTo(50, 10)}}
            editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      {
                        const data = [...devisTableData];
                        const index = data.findIndex(x => x.id === oldData.id);
                        data[index] = { ...newData };
                        const updateBody = {
                            comm: newData.comm,
                            staffs: `/api/staffs/${newData.createur_de_devis.id}`
                        }
                        this.waitForUpdateDevis(this.props.token, newData.id, updateBody, data)
                      }
                      resolve()
                    }, 1000)
                  })
              }}
            options={{
                grouping: false,
                draggable: false,
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 50, 100, 200],
                headerStyle: {
                    position: 'sticky',
                    top: 0,
                    width: "auto",
                    tableLayout: "auto"
                },
                    exportButton: true,
                    exportAllData: true,
                    exportDelimiter: ";",
            }}
            icons={{
                Edit: BorderColorRoundedIcon,
                Search: YoutubeSearchedForIcon ,
              }}
            isLoading={this.props.loading}
            onSearchChange={this.onSearchHandler}
            onChangeRowsPerPage={this.onRowsPerPageHandler}
        />);
        return (
            <Container>
       <div>
      </div>
     <br></br>
     <br></br>
     <br></br>
     <br></br>
       <center><h3>Devis</h3></center>
       {devis}
   </Container>
        )
    }
}
const mapStateToProps = state => {
    return {
        devis: state.devi.devis,
        loading: state.devi.loading,
        updatedDevis: state.devi.devi,
        totaC: state.devi.totalcount,
        token: state.auth.token,
        staff: state.staff.staff,
        loadingstaff: state.staff.loading
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onFetchDevis: (token, nbPage, searchTxt) => dispatch(actions.fetchDevis(token, nbPage, searchTxt)),
        onFetchStaff: (token) => dispatch(actions.fetchStaff(token)),
        onSetDevis: (token, id, commData, data2) => dispatch(actions.setDevis(token, id, commData, data2))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Devis)
