import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const fetchObjectifSuccess = (objectifs) => {
  return {
    type: actionTypes.FETCH_OBJECTIF_SUCCESS,
    objectifs: objectifs,
  };
};
export const fetchObjectifFail = (error) => {
  return {
    type: actionTypes.FETCH_OBJECTIF_FAIL,
    error: error
  };
};
export const fetchObjectifStart = () => {
  return {
    type: actionTypes.FETCH_OBJECTIF_START
  };
};
export const fetchObjectif = (token, newYear) => {
  return dispatch => {
    dispatch(fetchObjectifStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.get(`${BACK_BASE_URL}/objectif?year=${newYear}`, headerConfig)
      .then(res => {
        const fetchObjectif = [];
        for (const key in res.data) {
          fetchObjectif.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        dispatch(fetchObjectifSuccess(fetchObjectif));
      })
      .catch(err => {
        if (err.response === 401) {
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(fetchObjectifFail(err));
      })
  }
}
export const addObjectifSuccess = (Objectif, Objectifs) => {
  return {
    type: actionTypes.ADD_OBJECTIF_SUCCESS,
    objectifs: [...Objectifs],
    objectif: Objectif
  };
};
export const addObjectifFail = (error) => {
  return {
    type: actionTypes.ADD_OBJECTIF_FAIL,
    error: error
  };
};
export const addObjectifStart = () => {
  return {
    type: actionTypes.ADD_OBJECTIF_START
  };
};
export const addObjectif = (ObjectifBody, ObjectifsData, token) => {
  const headerConfig = {
    headers: { Authorization: `Bearer ${token}` }
  };
  return dispatch => {
    axios.post(`${BACK_BASE_URL}/postObjectif`, ObjectifBody)
      .then(res => {
        ObjectifsData.push({ ...res.data });
        dispatch(addObjectifSuccess(res.data, ObjectifsData));
        toastr.success('Objectif ajouter');
        axios.get(`${BACK_BASE_URL}/objectif?year=2020`, headerConfig)
          .then(res => {
            const fetchObjectif = [];
            for (const key in res.data) {
              fetchObjectif.push({
                ...res.data[key],
                counter: res.data[key].id,
              });
            }
            dispatch(fetchObjectifSuccess(fetchObjectif));
          })
      })
      .catch(err => {
        if (err.response.status === 401) {
          toastr.warning('your session is expired', 'Please Sign in again');
          dispatch(logout());
        }
        dispatch(addObjectifFail(err));
      })
  }
}
