import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    devis: [],
    devi: null,
    loading: false,
    totalcount: 0
}
const fetchDevisStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchDevisSuccess = (state, action) => {
    return updateObject(state, {
        devis: action.devis,
        loading: false,
        totalcount: action.total
    });
}
const fecthDevisFail = (state, action) => {
    return updateObject(state, { loading: false });
}
const setDevisStart = (state, action) => {
    return updateObject(state, {loading: true});
};
const setDevisSuccess = (state, action) => {
    return updateObject(state, {
        devis: action.devis,
        devi: action.devi,
        loading: false
    });
};
const setDevisFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const reducerPro = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DEVIS_START: return fetchDevisStart(state, action);
        case actionTypes.FETCH_DEVIS_SUCCESS: return fetchDevisSuccess(state, action);
        case actionTypes.FETCH_DEVIS_FAIL: return fecthDevisFail(state, action);
        case actionTypes.SET_DEVIS_START: return setDevisStart(state, action);
        case actionTypes.SET_DEVIS_SUCCESS: return setDevisSuccess(state, action);
        case actionTypes.SET_DEVIS_FAIL: return setDevisFail(state, action);
       default: return state;
    }
}
export default reducerPro;
