import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
import { fetchContactFail } from './contact';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const fetchContactSuccess = (contact) => {
  return {
      type: actionTypes.FETCH_CONTACT_SUCCESS,
      contact: contact
  };
};
export const fetchContactStart = () => {
  return {
      type: actionTypes.FETCH_CONTACT_START
  };
};
export const setSellsySuccess = (sellsy, sellsys) => {
    return {
        type: actionTypes.SET_SELLSY_SUCCESS,
        sellsy: sellsy,
        sellsys: sellsys
    };
};
 export const setSellsyFail = (error) => {
    return {
      type: actionTypes.SET_SELLSY_FAIL,
      error: error
    };
  };
  export const setSellsyStart = () => {
    return {
      type: actionTypes.SET_SELLSY_START
    };
  };
  export const setSellsy = (token, sellsyId, SellsysOutsideStore) => {
    return dispatch => {
      dispatch(setSellsyStart());
      const headerConfig = {
        headers: { Authorization: `Bearer ${token}` }
      };
      axios.put(`${BACK_BASE_URL}/postSellsy/${sellsyId}`, headerConfig)
        .then(res => {
          if (res.status === 201) {
            const sellsysWaiting = [...SellsysOutsideStore];
            const index = sellsysWaiting.findIndex((obj) => obj.id === sellsyId);
            if (index > -1) {
                sellsysWaiting.splice(index, 1);
            }
            dispatch(setSellsySuccess(sellsysWaiting));
            toastr.success('Succès', 'Le client est accepter');
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            toastr.warning(err.response.data.message, 'Please sign in again');
            dispatch(logout());
          }
          dispatch(fetchContactFail(err));
        })
        axios.get(`${BACK_BASE_URL}/api/users?roles=ROLE_USER&sellsy=false&isActive=true`)
        .then(res => {
            const fecthedContact = [];
           for (const key in res.data['hydra:member']) {
            fecthedContact.push({
                ...res.data['hydra:member'][key]
            });
        }
         dispatch(fetchContactSuccess(fecthedContact));
        })
        .catch(err => {
            if (err.response.status === 401) {
                toastr.warning(err.response.data.message, 'Sign in again');
                dispatch(logout());
            }
            dispatch(fetchContactFail(err));
        })
      }
  }
