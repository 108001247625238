import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateObject, checkValidity } from '../../shared/utility';
import * as actions from '../../store/actions/index';
import { toastr } from 'react-redux-toastr';
import '../../styles/sidebar.css';
import '../../styles/header.css';
import '../../styles/form.css';
class ResetPassword extends Component {
  state = {
    controls: {
      pass: {
        value: '',
        validation: {
          required: true,
          minLength: 5
        },
        valid: false,
        touched: false
      },
      confirmPassword: {
        value: '',
        validation: {
          required: true,
          minLength: 5
        },
        valid: false,
        touched: false
      }
    }
  }
  inputChangeHandler = (event) => {
    const eventValue = event.target.value;
    const inputName = event.target.name;
    const updatedElements = updateObject(this.state.controls, {
      [inputName]: updateObject(this.state.controls[inputName], {
        value: eventValue,
        valid: checkValidity(eventValue, this.state.controls[inputName].validation),
        touched: true
      })
    });
    this.setState({ controls: updatedElements });
  }
  disableSubmitHandler = () => {
    return !(this.state.controls.confirmPassword.value && this.state.controls.pass.valid);
  }
  getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === variable) {
        return pair[1];
      }
    }
    return (false);
  }
  submitHandler = (event) => {
    event.preventDefault();
    const newPasswordBody = {
      pass: this.state.controls.pass.value,
      token: this.getQueryVariable('token')
    }
    if (this.state.controls.pass.value === this.state.controls.confirmPassword.value) {
      this.props.onNewPassword(JSON.stringify(newPasswordBody));
      this.props.history.push('/');
    } else {
      toastr.warning('Vérifier votre mot de passe');
    }
  }
  routeChange() {
  }
  render() {
    return (
      <div >
        <br></br> <br></br>
        <center><h1>Bienvenue sur CosaFinance</h1></center>
        <hr class="my-5"></hr>
        <form onSubmit={this.submitHandler} class="form">
          <center>
            <fieldset class="col-md-5">
              <legend>Réinitialisation de votre mot de passe</legend>
              <br></br> <br></br>
              <div>
              <div className="form-group">
                  <label > Mot de passe  </label>
                  <input name="pass" type="password" className="form-control"
                    onChange={(event) => this.inputChangeHandler(event)} />
                  <div style={{ fontSize: 12, color: "red" }}>
                    {!this.state.controls.pass.valid && this.state.controls.pass.touched ? "Mot de passe très court" : null}
                  </div>
                </div>
<br></br>
                <div className="form-group">
                  <label>  Confirmation du mot de passe  </label>
                  <input name="confirmPassword" type="password" className="form-control"
                    onChange={(event) => this.inputChangeHandler(event)} />
                  <div style={{ fontSize: 12, color: "red" }}>
                    {!this.state.controls.confirmPassword.valid && this.state.controls.confirmPassword.touched ? "Mot de passe très court" : null}
                  </div>
                </div>
              </div>
            </fieldset>
          </center>
          <br></br>
          <center>
            <button id="sv_sign_btn" style={{'background-color': '#37035E', 'width': '40%' ,'border-radius': '8px', 'border': 'none'}}
            disabled={this.disableSubmitHandler()} type="submit" className="btn btn-primary btn-lg" onClick={() => this.routeChange}>Valider</button>
          </center>
        </form>
      </div >
    )
  }
}
const mapStateTpProps = state => {
  return {
    passwords: state.password.passwords,
    totaC: state.password.totalcount
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onNewPassword: (newPasswordBody) => dispatch(actions.newPwd(newPasswordBody))
  };
};
export default connect(mapStateTpProps, mapDispatchToProps)(ResetPassword);
