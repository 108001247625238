import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const fetchDetailSuccess = (singleDetail) => {
    return {
        type: actionTypes.FETCH_DETAIL_SUCCESS,
        singleDetail: singleDetail
    };
};
export const fetchDetailFail = (error) => {
    return {
        type: actionTypes.FETCH_DETAIL_FAIL,
        error: error
    };
};
export const fetchDetailStart = () => {
    return {
        type: actionTypes.FETCH_DETAIL_START
    };
};
export const fetchDetail = (token,id) => {
    return dispatch => {
        dispatch(fetchDetailStart());
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};
        axios.get(`${BACK_BASE_URL}/api/users/${id}?isActive=true`)
            .then(res => {
              dispatch(fetchDetailSuccess(res.data));
            })
            .catch(err => {
                if (err.response.status === 401) {
                   toastr.warning(err.response.data.message, 'Sign in again');
                    dispatch(logout());
                }
                dispatch(fetchDetailFail(err));
            })
    }
}
