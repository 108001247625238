import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { updateObject } from '../../shared/utility';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
class Demande extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      clientdetails: {
        id: null,
        infogennomsoc: '',
        infogenref: '',
        infogensiteweb: '',
        infogencategorietarif: '',
        coordsoctel: '',
        coordsocmobile: '',
        coordsocfax: '',
        coordsocemail: '',
        contactpcivilite: '',
        contactpprenom: '',
        contactpnom: '',
        contactpfonction: '',
        contactpemail: '',
        contactptel: '',
        contactpmobile: '',
        contactpfax: '',
        addresspnomadress: '',
        addressppartie1: '',
        addressppartie2: '',
        addressppartie3: '',
        addressppartie4: '',
        addressppays: '',
        addresspcodepostal: '',
        addresspville: '',
        autreinfformejuridique: '',
        autreinfsiret: '',
        autreinfsiren: '',
        autreinfcodenaf: '',
        autreinfcapitalsocial: '',
        autreinfrcs: '',
        autreinftva: '',
        socialtwitter: '',
        socialface: '',
        sociallinkedin: '',
        socialviadeo: '',
        note: ''
      }
    };
  }
  setClientInitialData = (data) => {
    const { id,
      infogennomsoc,
      infogenref,
      infogensiteweb,
      infogencategorietarif,
      coordsoctel,
      coordsocmobile,
      coordsocfax,
      coordsocemail,
      contactpcivilite,
      contactpprenom,
      contactpnom,
      contactpfonction,
      contactpemail,
      contactptel,
      contactpmobile,
      contactpfax,
      addresspnomadress,
      addressppartie1,
      addressppartie2,
      addressppartie3,
      addressppartie4,
      addressppays,
      addresspcodepostal,
      addresspville,
      autreinfformejuridique,
      autreinfsiret,
      autreinfsiren,
      autreinfcodenaf,
      autreinfcapitalsocial,
      autreinfrcs,
      autreinftva,
      socialtwitter,
      socialface,
      sociallinkedin,
      socialviadeo,
      note } = data;
    const updatedElements = updateObject(this.state.clientdetails, {
      id: id,
      infogennomsoc: infogennomsoc,
      infogenref: infogenref,
      infogensiteweb: infogensiteweb,
      infogencategorietarif: infogencategorietarif,
      coordsoctel: coordsoctel,
      coordsocmobile: coordsocmobile,
      coordsocfax: coordsocfax,
      coordsocemail: coordsocemail,
      contactpcivilite: contactpcivilite,
      contactpprenom: contactpprenom,
      contactpnom: contactpnom,
      contactpfonction: contactpfonction,
      contactpemail: contactpemail,
      contactptel: contactptel,
      contactpmobile: contactpmobile,
      contactpfax: contactpfax,
      addresspnomadress: addresspnomadress,
      addressppartie1: addressppartie1,
      addressppartie2: addressppartie2,
      addressppartie3: addressppartie3,
      addressppartie4: addressppartie4,
      addressppays: addressppays,
      addresspcodepostal: addresspcodepostal,
      addresspville: addresspville,
      autreinfformejuridique: autreinfformejuridique,
      autreinfsiret: autreinfsiret,
      autreinfsiren: autreinfsiren,
      autreinfcodenaf: autreinfcodenaf,
      autreinfcapitalsocial: autreinfcapitalsocial,
      autreinfrcs: autreinfrcs,
      autreinftva: autreinftva,
      socialtwitter: socialtwitter,
      socialface: socialface,
      sociallinkedin: sociallinkedin,
      socialviadeo: socialviadeo,
      note: note
    });
    this.setState({ clientdetails: updatedElements });
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  }
  handleAccept = () => {
    this.setState({
      open: false,
    });
    this.props.onSetDemandes(this.props.token, this.state.clientdetails.id, this.props.demandes);
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  toggleWindow = () => {
    this.setState({
      visibleWindow: !this.state.visibleWindow
    });
  }
  waitForUpdateDemandes(idDemandes) {
    this.props.onSetDemandes(this.props.token, idDemandes, this.props.demandes);
  }
  componentDidMount() {
    this.props.onFetchDemande(this.props.token);
  }
  render(props) {
    const { open } = this.state;
    const {
      coordsoctel,
      coordsocmobile,
      coordsocfax,
      coordsocemail,
      contactpcivilite,
      contactpprenom,
      contactpnom,
      contactpfonction,
      contactpemail,
      contactptel,
      contactpmobile,
      contactpfax,
      addresspnomadress,
      addressppartie1,
      addressppartie2,
      addressppartie3,
      addressppartie4,
      addressppays,
      addresspcodepostal,
      addresspville,
      autreinfformejuridique,
      autreinfsiret,
      autreinfsiren,
      autreinfcodenaf,
      autreinfcapitalsocial,
      autreinfrcs,
      autreinftva,
      socialtwitter,
      socialface,
      sociallinkedin,
      socialviadeo,
      note
    } = this.state.clientdetails;
    let users = <Spinner />
    let usersTable = null;
    if (!this.props.loading) {
      usersTable = this.props.demandes.map((user, index) => {
        return (<TableRow key={user.id}>
          <TableCell align="left">{user.contactpnom} </TableCell>
          <TableCell align="left">{user.contactpemail} </TableCell>
          <TableCell align="left">{user.infogenref} </TableCell>
          <TableCell align="left">{user.infogensiteweb} </TableCell>
          <TableCell align="left">{user.infogencategorietarif} </TableCell>
          <TableCell align="left">
            <div>
              <Button
                color="primary"
                onClick={(e) => {
                  this.setClientInitialData(user);
                  this.handleClickOpen();
                }} >Details</Button>
            </div>
          </TableCell>
        </TableRow >)
      }
      );
      users = (
        <TableContainer component={Paper} >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Nom </TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Réference</TableCell>
                <TableCell align="left">Site web</TableCell>
                <TableCell align="left">Categorie tarifaire</TableCell>
                <TableCell align="left">Détails</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersTable}
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
    return (
      <div>
        {users}
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Plus de détails...."}</DialogTitle>
          <DialogContent>
            <div>
              <div>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header" >
                    <Typography >Coordonnées société </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <p><strong>Société: </strong>  {coordsoctel} </p>
                      <p><strong>Mobile: </strong>  {coordsocmobile} </p>
                      <p><strong> Fax: </strong>  {coordsocfax} </p>
                      <p><strong>Email: </strong>  {coordsocemail} </p>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography >Contact principal</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <p><strong>Civilité : </strong>  {contactpcivilite} </p>
                      <p><strong>Email: </strong>  {contactpprenom} </p>
                      <p><strong>Nom: </strong>  {contactpnom} </p>
                      <p><strong> Fonction: </strong>  {contactpfonction} </p>
                      <p><strong>Email: </strong>  {contactpemail} </p>
                      <p><strong>Tél: </strong>  {contactptel} </p>
                      <p><strong>Mobile: </strong>  {contactpmobile} </p>
                      <p><strong>Fax: </strong>  {contactpfax} </p>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography >Adresse principale</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <p><strong>Civilité : </strong>  {addresspnomadress} </p>
                      <p><strong>Partie 1: </strong>  {addressppartie1} </p>
                      <p><strong>Partie 2: </strong>  {addressppartie2} </p>
                      <p><strong>Partie 3: </strong>  {addressppartie3} </p>
                      <p><strong>Partie 4: </strong>  {addressppartie4} </p>
                      <p><strong>Pays: </strong>  {addressppays} </p>
                      <p><strong>Code postal: </strong>  {addresspcodepostal} </p>
                      <p><strong>Ville: </strong>  {addresspville} </p>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography >Social </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <p><strong>Twitter  : </strong>  {socialtwitter} </p>
                      <p><strong>Facebook : </strong>  {socialface} </p>
                      <p><strong>Linkedin :  </strong>  {sociallinkedin} </p>
                      <p><strong>Viadeo : </strong>  {socialviadeo} </p>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography >Autres informations</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <p><strong>Forme juridique : </strong>  {autreinfformejuridique} </p>
                      <p><strong>N°Siret : </strong>  {autreinfsiret} </p>
                      <p><strong>N°Siren : </strong>  {autreinfsiren} </p>
                      <p><strong>Code NAF (APE)  : </strong>  {autreinfcodenaf} </p>
                      <p><strong>Capital social : </strong>  {autreinfcapitalsocial} </p>
                      <p><strong>RCS : </strong>  {autreinfrcs} </p>
                      <p><strong>N°TVA intra: </strong>  {autreinftva} </p>
                      <p><strong>Note : </strong>  {note} </p>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleAccept} color="primary">
              Accepter
          </Button>
            {}
          </DialogActions>
        </Dialog>
      </div >
    )
  }
}
const mapStateToProps = state => {
  return {
    demandes: state.demande.demandes,
    loading: state.demande.loading,
    token: state.auth.token,
    total: state.demande.total,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDemande: (token) => dispatch(actions.fetchDemande(token)),
    onSetDemandes: (token, id, data2) => dispatch(actions.setDemandes(token, id, data2))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Demande)
