import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import MaterialTable from 'material-table';
import { Container } from '@material-ui/core';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';


class Facture extends Component {
     
    state = {
        page: 0,
        search: '',
        windowHeight: window.innerWidth
    }
    columns = [
        { title: 'Comptes', field: 'nom', editable: 'never' },
        { title: 'Total encaissable', field: 'totalduu', editable: 'never', type: 'float' },
        { title: 'Encaissé', field: 'totpayeSells', editable: 'onUpdate' },
        {
            title: '0-30jrs', field: 'reatrdA', editable: 'never', type: 'float', headerStyle: {
                backgroundColor: '#F5B7B1',
            }, cellStyle: {
                size: '100%'
            },
        },
        {
            title: '31-60jrs', field: 'reatrdB', editable: 'never', type: 'float', headerStyle: {
                backgroundColor: '#EC7063',
            }
        },
        {
            title: '61-90jrs', field: 'reatrdC', editable: 'never', type: 'float', headerStyle: {
                backgroundColor: '#E74C3C',
            }
        },
        {
            title: '+90jours', field: 'reatrdD', editable: 'never', type: 'float', headerStyle: {
                backgroundColor: '#CB4335',
            }
        },
        {
            title: 'A échoir 0-30j', field: 'courant', editable: 'never', type: 'float', headerStyle: {
                backgroundColor: '#D0D3D4',
            }
        },
        { title: 'commentaire', field: 'commentaire', editable: 'onUpdate' },
    ]
    componentDidMount() {    
        window.addEventListener("resize", this.handleResize);    
        this.props.onFetchFacture(this.props.token, this.state.page, this.state.itemsPerPage, this.state.search);
    }
    waitForUpdateFacture(token, FactureId, DataUpdt, data) {
        this.props.onSetFacture(token, FactureId, DataUpdt, data);
        //this.props.onFetchFacture(this.props.token, this.state.page, this.state.itemsPerPage, this.state.search);
    }
    onSearchHandler = (searchText) => {
        if (this.state.page !== 1) {
            this.setState({ page: 0 })
        }
        this.setState({ search: searchText });
        this.props.onFetchFacture(this.props.token, 0, this.state.itemsPerPage, searchText)
    }
    onPageChangeHandler = (event, newPage) => {
        this.setState({ page: newPage })
        this.props.onFetchFacture(this.props.token, newPage, this.state.itemsPerPage, this.state.search);
    }
    onRowsPerPageHandler = (pageSize) => {
        if (this.state.page !== 0) {
            this.setState({ page: 0 })
        }
        this.setState({ itemsPerPage: pageSize })
        this.props.onFetchFacture(this.props.token, 0, pageSize, this.state.search);
    }
   
    separateurNumber =  (a, b) => {
        a = '' + a;
        b = b || ' ';
        var c = '',
            d = 0;
        while (a.match(/^0[0-9]/)) {
          a = a.substr(1);
        }
        for (var i = a.length-1; i >= 0; i--) {
          c = (d !== 0 && d % 3 === 0) ? a[i] + b + c : a[i] + c;
          d++;
        }
        return c;
      }
         handleResize = (e) => {
        this.setState({ windowHeight: window.innerHeight });
       };
      
      /* componentDidMount() {
        window.addEventListener("resize", this.handleResize);
       }*/
      
       componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
       } 
    render() {
        const facturesTableData = [];
        for (const key in this.props.factures) {
            const montant = (this.props.factures[key].montnt) ? this.props.factures[key].montnt : 0;
            const totpayeSells = (this.props.factures[key].totpayeSellsy) ? this.props.factures[key].totpayeSellsy : 0;
            const montantpayee = (this.props.factures[key].montantpaye) ? this.props.factures[key].montantpaye : 0;
            const totaldu = (this.props.factures[key].totaldu) ? this.props.factures[key].totaldu : (parseFloat(montant) - (parseFloat(totpayeSells) + parseFloat(montantpayee)));
            facturesTableData.push({
                montant: montant,
                totpayeSells: totpayeSells,
                montantpayee: montantpayee,
                //totalduu: (parseFloat(montant) - (parseFloat(totpayeSells) + parseFloat(montantpayee))),
                totalduu: totaldu ,
                ...this.props.factures[key]
            });
        }
        const facture = (<MaterialTable
            title="Suivi des comptes clients"
            columns={[
                { title: 'Comptes', field: 'nom', editable: 'never' },
                { title: 'Total encaissable', field: 'totalduu', editable: 'never', type: 'float', render : props =>  this.separateurNumber(props.totalduu) },
                { title: 'Encaissé', field: 'totpayeSells', editable: 'onUpdate', render : props =>  this.separateurNumber(props.totpayeSells)  },
                {
                    title: '0-30jrs', field: 'reatrdA', editable: 'never', type: 'float',  render : props =>  this.separateurNumber(props.reatrdA), headerStyle: {
                        backgroundColor: '#F5B7B1',
                    }, cellStyle: {
                        size: '100%'
                    },
                },
                {
                    title: '31-60jrs', field: 'reatrdB', editable: 'never', type: 'float', render : props =>  this.separateurNumber(props.reatrdB),headerStyle: {
                        backgroundColor: '#EC7063',
                    }
                },
                {
                    title: '61-90jrs', field: 'reatrdC', editable: 'never', type: 'float', render : props =>  this.separateurNumber(props.reatrdC),headerStyle: {
                        backgroundColor: '#E74C3C',
                    }
                },
                {
                    title: '+90jours', field: 'reatrdD', editable: 'never', type: 'float', render : props =>  this.separateurNumber(props.reatrdD),headerStyle: {
                        backgroundColor: '#CB4335',
                    }
                },
                {
                    title: 'A échoir 0-30j', field: 'courant', editable: 'never', type: 'float',render : props =>  this.separateurNumber(props.courant), headerStyle: {
                        backgroundColor: '#D0D3D4',
                    }
                },
                { title: 'commentaire', field: 'commentaire', editable: 'onUpdate' },
            ]}
            data={facturesTableData}
            onChangePage = {() => {window.scrollTo(50, 10)}}
            editable={{
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            {
                                const data = [...facturesTableData];
                                const index = data.findIndex(x => x.id === oldData.id);
                                data[index] = { ...newData };
                                const updateBody = {
                                    commentaire: newData.commentaire,
                                    totpayeSellsy: parseFloat(newData.totpayeSells),
                                }
                                this.waitForUpdateFacture(this.props.token, newData.id, updateBody, data)
                            }
                            resolve()
                        }, 1000)
                    })
            }}
            options={{
                maxBodyHeight: this.state.windowHeight - 200  , 
                grouping: false,
                draggable: false,
                actionsColumnIndex: -1,
                pageSize: 20,
                pageSizeOptions: [50, 100, 200],
                headerStyle: {
                    position: 'sticky',
                    top: 0,
                    width: "auto",
                    tableLayout: "auto"
                },
                exportButton: true,
                exportAllData: true,
                exportDelimiter: ";",
            }}
            icons={{
                Edit: BorderColorRoundedIcon,
                Search: YoutubeSearchedForIcon,
            }}
            isLoading={this.props.loading}
            onSearchChange={this.onSearchHandler}
            onChangeRowsPerPage={this.onRowsPerPageHandler}
        />);
        return (
            <Container>
                <div>
                </div>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <center><h3>L'encaisseur </h3></center>
                {facture}
            </Container>
        )
    }
}
const mapStateToProps = state => {
    return {
        factures: state.facture.factures,
        loading: state.facture.loading,
        updatedFacture: state.facture.facture,
        token: state.auth.token,
        totaC: state.facture.totalcount
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onFetchFacture: (token, nbPage, searchTxt) => dispatch(actions.fetchFacture(token, nbPage, searchTxt)),
        onSetFacture: (token, id, updateBody, data2) => dispatch(actions.setFacture(token, id, updateBody, data2))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Facture)



