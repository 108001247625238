import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    users: [],
    user: null,
    loading: false
}
const fetchUserStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchUserSuccess = (state, action) => {
    return updateObject(state, {
        users: action.users,
        loading: false
    });
};
const fecthUserFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const setUserStart = (state, action) => {
    return updateObject(state, { loading: true });
};
const setUserSuccess = (state, action) => {
    return updateObject(state, {
        user: action.user,
        loading: false
    });
};
const setUserFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_USERS_START: return fetchUserStart(state, action);
        case actionTypes.FETCH_USERS_SUCCESS: return fetchUserSuccess(state, action);
        case actionTypes.FETCH_USERS_FAIL: return fecthUserFail(state, action);
        case actionTypes.SET_USER_START: return setUserStart(state, action);
        case actionTypes.SET_USER_SUCCESS: return setUserSuccess(state, action);
        case actionTypes.SET_USER_FAIL: return setUserFail(action, state);
        default: return state;
    }
};
export default reducer;
