import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    sellsys: [],
    sellsy: null,
    loading: false,
    totalcount: 0
}
const setSellsyStart = (state, action) => {
    return updateObject(state, {loading: true});
};
const setSellsySuccess = (state, action) => {
    return updateObject(state, {
        sellsys: action.sellsys,
        sellsy: action.sellsy,
        loading: false
    });
};
const setSellsyFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const reducerSell = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELLSY_START: return setSellsyStart(state, action);
        case actionTypes.SET_SELLSY_SUCCESS: return setSellsySuccess(state, action);
        case actionTypes.SET_SELLSY_FAIL: return setSellsyFail(state, action);
        default: return state;
    }
}
export default reducerSell;
