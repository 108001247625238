import React from 'react';
import { NavLink } from 'react-router-dom';
export default function NavigationItem(props) {
    return (
        <li className="nav-item active">
            <NavLink
                to={props.link}
                exact={props.exact}
                activeClassName="nav-link">
                    {props.children}
            </NavLink>
        </li>
    )
}
