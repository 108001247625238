import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import AddIcon from '@material-ui/icons/Add';
import * as actions from '../../store/actions/index';
import { connect } from "react-redux";
import { TextField, Button,  NativeSelect,Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Container } from "reactstrap";
import { DatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import moment from 'moment';
import 'moment/locale/fr';
import { updateObject } from "../../shared/utility";
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white
    },
    body: {
        fontSize: 14
    }
}))(TableCell);
const StyledTableRow = withStyles(theme => ({
    root: {
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover
        }
    }
}))(TableRow);
const styles = {
    table: {
        minWidth: 700
    }
}
class Objectifs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            year: moment().format('yyyy'),
            objectifBody: {
                smartTag: '',
                date: new Date(),
                objectif: ''
            }
        }
    }
    componentDidMount() {
        this.props.onFetchObjectif(this.props.token, moment().format('yyyy'));
        this.props.onFetchSmart(this.props.token)
    }
    handleOpen = () => {
        this.setState({ open: true });
    }
    handleClose = () => {
        this.setState({ open: false });
    }
    handleChangeYear(newYear) {
        moment(newYear).format('yyyy')
        this.setState({
            year: moment(newYear).format('yyyy')
        }, () => this.props.onFetchObjectif(this.props.token, this.state.year))
    }
    onChangeStandard = (e) => {
        this.setState({ [e.target.name]: e.target.value }
           )
    }
    handleOnChange = (e) => {
        const updatedElements = updateObject(this.state.objectifBody,{
            [e.target.name]: e.target.value
        })
        this.setState({ objectifBody: updatedElements})
    }
    handleSave = () => {
        const{smartTag, date, objectif}= this.state.objectifBody;
        const objectifBody ={
            smartTag: smartTag,
            date: date,
            objectif: objectif

        }
        this.props.onAddObjectif(objectifBody, this.props.objectifs);
        this.props.onFetchObjectif(this.props.token, moment().format('yyyy'));
        const updatedElements = updateObject(this.state.objectifBody,{
            smartTag: '',
            date: new Date(),
            objectif: ''
        })
        this.setState({
            objectifBody: updatedElements,
            open: false
        })
    }
    handleDisabled = () => {
        const { objectif, smartTag} = this.state.objectifBody;
        if(objectif && smartTag) {
            return false
        }
        return true;
    }
    render(props) {
        const { open, year, objectifBody } = this.state;
        const { objectif, date, smartTag} = objectifBody;
        const { classes } = this.props;
        let columnss = [];
        const fdata = [];
        if (!this.props.loading && this.props.objectifs) {
             this.props.objectifs.map(
                e =>
                    (fdata[e.date_obj] = {
                        ...fdata[e.date_obj],
                        date: formatMonth(new Date(e.date_obj).getMonth()),
                        [e.nom]: { obj: (new Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 0
                          }).format(e.obj)), diff: (new Intl.NumberFormat('fr-FR', {
                            style: 'currency',
                            currency: 'EUR',
                            minimumFractionDigits: 0
                          }).format(e.diff)), achievement: parseInt(((e.diff)*100)/(e.obj))+'%' }
                    })
            );
            columnss = this.props.objectifs
                .reduce((c, e) => c.push(e.nom) && c, [])
                .filter((value, index, self) => self.indexOf(value) === index);
        }
        return (
            <div>
                <Container>
                    <center><h3>Suivi des objectifs </h3></center>
                </Container>
                <div>
                    <Table>
                        <tr>
                            <td>
                                <Button onClick={this.handleOpen} >
                                    <AddIcon /> </Button>
                                <Dialog
                                    open={open}
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">{"Ajouter un objectif"}</DialogTitle>
                                    <DialogContent>
                                        <div>
                                            <div>
                                                <ExpansionPanel>
                                                    <Table>
                                                        <tr><td>Choisir un smartTags : </td>
                                                            <NativeSelect
                                                                name="smartTag"
                                                                value={smartTag}
                                                                onChange={(event) => this.handleOnChange(event)}
                                                                class="custom-select"
                                                                id="inputGroupSelect01"
                                                                  >
                                                              <option key={null} value={null}></option>
                                                                {this.props.smart.map((user, index) => {
                                                                    return <option
                                                                        key={user.id}
                                                                        value={user.id}>
                                                                        {user.nom}
                                                                    </option>
                                                                })}
                                                            </NativeSelect>
                                                        </tr>
                                                        <tr><td>Choisir date: </td>
                                                            <td> <TextField
                                                                name="date"
                                                                value={date}
                                                                onChange={(event) => this.handleOnChange(event)}
                                                                id="date"
                                                                label="Date"
                                                                type="month"
                                                                className={classes.textField}
                                                                InputLabelProps={{
                                                                    shrink: true,
                                                                }}
                                                            /></td>
                                                        </tr>
                                                        <tr> <td>Entrer l'objectif souhaité  </td>
                                                            <td> <TextField
                                                            name="objectif"
                                                            value={objectif}
                                                            onChange={(event) => this.handleOnChange(event)}
                                                            id="standard-basic"
                                                            label="Entrer l'objectif souhaité "
                                                            type="number" /></td>
                                                            </tr>
                                                        </Table>
                                                    </ExpansionPanel>
                                                </div>
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                            color="primary"
                                            onClick={() => this.handleSave()}
                                            disabled={this.handleDisabled()}  >
                                                Accepter
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                            </td>
                                <td align='right'>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                        <DatePicker
                                            autoOk
                                            size="small"
                                            allowKeyboardControl={true}
                                            variant="inline"
                                            inputVariant="outlined"
                                            views={['year']}
                                            onChange={(e) => this.handleChangeYear(e)}
                                            style={{ width: '180px', backgroundColor: 'white' }}
                                            label="Pick Year"
                                            value={year}
                                            renderInput={props =>
                                                <TextField
                                                    {...props}
                                                    name="Year"
                                                    variant="outlined"
                                                    style={{ width: '180px', backgroundColor: 'white' }} />}
                                        />
                                    </MuiPickersUtilsProvider>
                                </td>
                        </tr></Table>
                </div>
                    <br></br>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Date</StyledTableCell>
                                    {columnss.map(e => (
                                        <StyledTableCell align="center">
                                            {e}
                                            <TableRow width="33%">
                                                <StyledTableCell width="50%" align="center">
                                                    Objectif
                                            </StyledTableCell>
                                                <StyledTableCell width="50%" align="center">
                                                    Atteint
                                             </StyledTableCell>
                                                <StyledTableCell width="50%" align="center">
                                                    %de réalisation
                                            </StyledTableCell>
                                            </TableRow>
                                        </StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(fdata).map(row => (
                                    <StyledTableRow key={row.nom}>
                                        <StyledTableCell scope="row">{row.date}</StyledTableCell>
                                        {columnss.map(e => (
                                            <StyledTableCell width="33%" align="center">
                                                <TableRow>
                                                    {row[e] &&
                                                        Object.values(row[e]).map(cell => (
                                                            <TableCell width="50%" align="center">
                                                                {cell}
                                                            </TableCell>
                                                        ))}
                                                </TableRow>
                                            </StyledTableCell>
                                        ))}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
        );
    }
}
const formatMonth = month => {
    return moment().month(month).locale('fr').format('MMMM');
}
const mapStateToProps = state => {
    return {
        objectifs: state.objectif.objectifs,
        loading: state.objectif.loading,
        token: state.auth.token,
        totaC: state.objectif.totalcount,
        smart: state.smart.smart
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onFetchObjectif: (token, newYear) => dispatch(actions.fetchObjectif(token, newYear)),
        onFetchSmart: (token) => dispatch(actions.fetchSmart(token)),
        onAddObjectif: (objectifBody, ObjectifsData ) => dispatch(actions.addObjectif(objectifBody, ObjectifsData))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Objectifs))
