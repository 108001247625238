import * as actionTypes from './actions-types';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const newPwdSuccess = (password, passwords) => {
    return {
        type: actionTypes.NEWPWD_SUCCESS,
        passwords: [...passwords],
        password: password
    };
};
export const newPwdFail = (error) => {
    return {
        type: actionTypes.NEWPWD_FAIL,
        error: error
    };
};
export const newPwdStart = () => {
    return {
        type: actionTypes.NEWPWD_START
    };
};
export const newPwd = (pwdData) => {
    return dispatch => {
        dispatch(newPwdStart());
        const headers = {
            'Content-Type': 'application/json'
        };
        axios.post(`${BACK_BASE_URL}/api/users/new_password`, pwdData, { headers })
            .then(res => {
                dispatch(newPwdSuccess(res.data['hydra:member'], pwdData));
                toastr.success('Mot de passe changé avec succès');
            })
            .catch(err => {
            })
    }
}
