import React from 'react'
import NavigationItems from '../../components/Navigation/NavigationItems';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root1: {
         display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));
function Layout(props)  {
    const classes = useStyles();
       return (
            <div className={classes.root1}>
                <NavigationItems isAuth={props.isAuthen} />
                <main className={classes.content}>
                    {props.children}
            </main>
            </div>
        )
}
export default Layout;
