import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    passwords: [],
    password: null,
    loading: false,
    totalcount: 0,
}
const newPwdStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const newPwdSuccess = (state, action) => {
    return updateObject(state, {
        passwords: action.passwords,
        password: action.password,
        loading: false,
        totalcount: action.total
    });
}
const newPwdFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const reducerNewPwd = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NEWPWD_START: return newPwdStart(state, action);
        case actionTypes.NEWPWD_SUCCESS: return newPwdSuccess(state, action);
        case actionTypes.NEWPWD_FAIL: return newPwdFail(state, action);
        default: return state;
    }
}
export default reducerNewPwd;
