import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { Row, Col } from 'reactstrap';
import { Container } from '@material-ui/core';
import Spinner from '../../components/UI/Spinner/Spinner';
class Product extends Component {
  state = {
    name: '',
    nomentreprise: ''
  }
  componentDidMount() {
    this.props.onFetchEntreprise(this.props.token);
  }
  errorFlag = () => {
    const {  nomentreprise } = this.state;
    return !!( nomentreprise);
  }
  onChangeStandard = (e) => {
    this.setState({ [e.target.name]: e.target.value },)
  }
  handleAccept() {
    const { nomentreprise } = this.state;
    const { id } = this.props.location.state;
    const contactBody = {
      contactentreprise: `/api/entreprises/${nomentreprise}`
    }
    this.props.onSetContact(this.props.token, id, contactBody, this.props.contact);
    this.props.onFetchContact(this.props.token, this.state.page, this.state.itemsPerPage, this.state.search);
    this.props.history.push('/contact');
  }
  handleAnnuler() {
    this.props.history.push('/contact');
  }
  render(props) {
    let users = <Spinner />
    let usersTable = null;
    if (!this.props.loading) {
      usersTable = (
        <center><tr>
          <td><select
            name="nomentreprise"
            class="custom-select"
            id="inputGroupSelect01"
            value={null}
            onChange={(event) => this.onChangeStandard(event)}
          >
            <option key={null} value={null}></option>
            {this.props.entreprise.map((user, index) => {
              if (user.nomentreprise ) {
                return <option
                  key={user.id}
                  value={user.id}>
                  {user.nomentreprise}
                </option>
              } else {
                return null;
              }
            })}
          </select> </td>
        </tr></center>
      );
      users = (
        <div>
          <center> <table className="table table-light">
            <thead>
              <center> <tr>
                <th scope="col" style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>Client</th>
              </tr></center>
            </thead>
            <tbody>
              {usersTable}
            </tbody>
          </table></center>
          <br></br><br></br><br></br><br></br>
          <button class="btn btn-primary" type="submit" onClick={() => this.handleAccept()}
            disabled={!this.errorFlag()}
          >Sauvgarder</button>
          <button class="btn btn-primary" type="submit" onClick={() => this.handleAnnuler()} >Annuler</button>
        </div>
      );
    }
    return (
      <Container>
        <div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <Row>
          <Col xs={8} md={{ offset: 2 }}>
            <center><h3>Attribuer le contact à une fiche client </h3></center>
            {users}
          </Col>
        </Row>
      </Container>
    )
  }
}
const mapStateToProps = state => {
  return {
    contact: state.contacts.contact,
    loading: state.products.loading,
    token: state.auth.token,
    totaC: state.products.totalcount,
    detail: state.details.detail,
    singleDetail: state.details.singleDetail,
    entreprise: state.entreprise.entreprise
  };

};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDetail: (token) => dispatch(actions.fetchDetail(token)),
    onFetchContact: (token) => dispatch(actions.fetchContact(token)),
    onSetContact: (token, id, contactData, contactsData) => dispatch(actions.setContact(token, id, contactData, contactsData)),
    onFetchEntreprise: (token) => dispatch(actions.fetchEntreprise(token)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Product)
