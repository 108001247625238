import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    factures: [],
    facture: null,
    loading: false,
    totalcount: 0
}
const fetchFactureStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchFactureSuccess = (state, action) => {
    return updateObject(state, {
        factures: action.factures,
        loading: false,
        totalcount: action.total
    });
}
const fecthFactureFail = (state, action) => {
    return updateObject(state, { loading: false });
}
const setFactureStart = (state, action) => {
    return updateObject(state, {loading: true});
};
const setFactureSuccess = (state, action) => {
    return updateObject(state, {
        factures: action.factures,
        facture: action.facture,
        loading: false
    });
};
const setFactureFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const reducerFact = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_FACTURECLIENT_START: return fetchFactureStart(state, action);
        case actionTypes.FETCH_FACTURECLIENT_SUCCESS: return fetchFactureSuccess(state, action);
        case actionTypes.FETCH_FACTURECLIENT_FAIL: return fecthFactureFail(state, action);
        case actionTypes.SET_FACTURECLIENT_START: return setFactureStart(state, action);
        case actionTypes.SET_FACTURECLIENT_SUCCESS: return setFactureSuccess(state, action);
        case actionTypes.SET_FACTURECLIENT_FAIL: return setFactureFail(state, action);
        default: return state;
    }
}
export default reducerFact;
