import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    contact: [],
    loading: false
}
const fetchContactStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchContactSuccess = (state, action) => {
    return updateObject(state, {
        contact: action.contact,
        loading: false
    });
}
const fecthContactFail = (state, action) => {
    return updateObject(state, { loading: false });
}
const setContactStart = (state, action) => {
    return updateObject(state, {loading: true});
};
const setContactSuccess = (state, action) => {
    return updateObject(state, {
        contacts: action.contacts,
        contact: action.contact,
        loading: false
    });
};
const setContactFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const reducerCon = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CONTACT_START: return fetchContactStart(state, action);
        case actionTypes.FETCH_CONTACT_SUCCESS: return fetchContactSuccess(state, action);
        case actionTypes.FETCH_CONTACT_FAIL: return fecthContactFail(state, action);
        case actionTypes.SET_CONTACT_START: return setContactStart(state, action);
        case actionTypes.SET_CONTACT_SUCCESS: return setContactSuccess(state, action);
        case actionTypes.SET_CONTACT_FAIL: return setContactFail(state, action);
        default: return state;
    }
}
export default reducerCon;
