import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { connect } from 'react-redux';
import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import AdminPanel from './containers/AdminPanel/AdminPanel';
import * as actions from './store/actions/index';
import Layout from './hoc/Layout/Layout';
import Devis from './containers/Devis/Devis';
import Contact from './containers/Contact/Contact';
import Product from './containers/Product/Product';
import Detail from './containers/Detail/Detail';
import Inscription from './containers/Inscription/Inscription';
import Demande from './containers/Demande/Demande';
import Cosasuccessinscrip from './containers/Welcome/Cosasuccessinscrip';
import Facture from './containers/Facture/Facture';
import Stat from './containers/Stat/Stat';
import Objectifs from './containers/Objectifs/Objectifs';
import ForgetPwd from './containers/ForgetPwd/ForgetPwd';
import ResetPassword from './containers/ResetPassword/ResetPassword';
class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
  }
  render() {
    let routes = (
      <Switch>
        <Route exact path="/" component={Auth} />
        <Route path="/inscription" component={Inscription} />
        <Route path="/success" component={Cosasuccessinscrip} />
        <Route path="/forgetpwd" component={ForgetPwd}/>
        <Route path="/reset" component={ResetPassword}/>
        <Redirect to="/" />
      </Switch>
    );
    if (this.props.isAuthenticated){
      routes = (<Layout>
          <Switch>
            <Route path="/adminpanel" component={AdminPanel} />
            <Route path="/devis" component={Devis} />
            <Route path="/contact" component={Contact}/>
            <Route path="/product" component={Product}/>
            <Route path="/detail" component={Detail}/>
            <Route path="/inscription" component={Inscription}/>
            <Route path="/demande" component={Demande}/>
            <Route path="/success" component={Cosasuccessinscrip}/>
            <Route path="/facture" component={Facture}/>
            <Route path="/Statistique" component={Stat} />
            <Route path="/Objectifs"  component={Objectifs}/>
            <Route path="/logout" component={Logout}/>
            <Redirect to="/contact" />
          </Switch>
        </Layout>
      );
     }
    return (
      <div className="App">
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          getState={(state) => state.toastr}
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={false}
          closeOnToastrClick />
        {routes}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
