
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import MaterialTable from 'material-table';
import { Container } from '@material-ui/core';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import Button from '@material-ui/core/Button';
class Contact extends Component {
  editUser(id) {
    window.localStorage.setItem("userId", id);
    this.props.onFetchDetail(this.props.token, id);
    this.props.history.push('/detail');
  }
  AddSellsy(id) {
    window.localStorage.setItem("userId", id);
    this.props.onSetSellsy(this.props.token, id, this.props.contact);
    this.props.onFetchContact(this.props.token, this.state.page, this.state.itemsPerPage, this.state.search);
  }
  editContact(id) {
    this.props.history.push({
      pathname: '/product',
      state: { id: id }
    })
  }
  waitForUpdateDemandes(idSellsy) {
    this.props.onSetSellsy(this.props.token, idSellsy, this.props.sellsy);
    this.props.onFetchContact(this.props.token, this.state.page, this.state.itemsPerPage, this.state.search);
  }
  state = {
    page: 0,
    search: '',
  }
  columns = [
    { title: 'Entreprise', field: 'contactentreprise.nomentreprise', editable: 'onUpdate' },
    { title: 'Prénom', field: 'prenom', editable: 'onUpdate' },
    { title: 'Nom', field: 'nom', editable: 'onUpdate' },
    { title: 'Email', field: 'email', editable: 'onUpdate' },
    {
      title: 'Attribué', field: '', editable: 'never',
      render: (columns) => {
        if (columns.contactentreprise.thirdid == null) {
          return (
            <Button variant="contained" color="primary" disableElevation onClick={() => this.editContact(columns.id)} >Non attribué</Button>
          )
        } else {
          return (
            <Button variant="contained" color="primary" disableElevation onClick={() => this.editUser(columns.id)} >attribué</Button>
          )
        }
      },
    },
    {
      title: 'Sellsy', field: '', editable: 'never',
      render: (columns) => {
        if (columns.sellsy === 'true') {
          return (
            <Button variant="contained" color="primary" disabled >Ajouter</Button>
          )
        } else {
          return (
            <Button variant="contained" color="primary" disableElevation onClick={() => this.AddSellsy(columns.id)} >Ajouter</Button>
          )
        }
      },
    }
  ]
  componentDidMount() {
    this.props.onFetchContact(this.props.token, this.state.page, this.state.itemsPerPage, this.state.search);
  }
  onSearchHandler = (searchText) => {
    if (this.state.page !== -1) {
      this.setState({ page: 0 })
    }
    this.setState({ search: searchText });
    this.props.onFetchContact(this.props.token, 0, this.state.itemsPerPage, searchText)
  }
  onPageChangeHandler = (event, newPage) => {
    this.setState({ page: newPage })
    this.props.onFetchContact(this.props.token, newPage, this.state.itemsPerPage, this.state.search);
  }
  handleUpdate = (idContact, newData, contactsData) => {
    const contactData = {
      nom: newData.nom,
      prenom: newData.prenom,
      contactentreprise: {
        id: `/api/entreprises/${newData.contactentreprise.id}`,
        nomentreprise: newData.contactentreprise.nomentreprise,
      }
    }
    this.props.onSetContact(this.props.token, idContact, contactData, contactsData);
  }
  render() {
    const contactTableData = [];
    for (const key in this.props.contact) {
      contactTableData.push({
        idCheck: `Check ${this.props.contact[key].id}`,
        ...this.props.contact[key]
      });
    }
     const contact = (<MaterialTable
      title="Contacts de facturation"
      columns={this.columns}
      data={contactTableData}
      onChangePage = {() => {window.scrollTo(50, 10)}}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = [...contactTableData];
                const index = data.indexOf(oldData);
                data[index] = { ...newData };
                this.handleUpdate(newData.id, newData, data);
              }
              resolve()
            }, 1000)
          })
      }}
      options={{
        grouping: false,
        draggable: false,
        pageSize: 9,
        pageSizeOptions: [50, 100, 200],
        headerStyle: {
          position: 'sticky',
          top: 0,
          width: "auto",
          tableLayout: "auto"
      },
          maxBodyHeight: '650px',
        exportButton: true,
      }}
      icons={{
        Edit: BorderColorRoundedIcon,
        Search: YoutubeSearchedForIcon,
      }}
      isLoading={this.props.loading}
      onSearchChange={this.onSearchHandler}
      onChangeRowsPerPage={this.onRowsPerPageHandler}

    />);
    return (
      <Container>
        <div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <center><h3>Contacts de facturation </h3></center>
        {contact}
      </Container>
    )
  }
}
const mapStateToProps = state => {
  return {
    contact: state.contacts.contact,
    loading: state.contacts.loading,
    token: state.auth.token,
    totaC: state.contacts.totalcount,
    detail: state.details.detail,
    sellsy: state.sellsy.sellsy,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchContact: (token) => dispatch(actions.fetchContact(token)),
    onFetchDetail: (token, id) => dispatch(actions.fetchDetail(token, id)),
    onFetchContact1: (token, id) => dispatch(actions.fetchContact(token, id)),
    onSetContact: (token, id, contactData, contactsData) => dispatch(actions.setContact(token, id, contactData, contactsData)),
    onSetSellsy: (token, sellsyId, sellsyBody) => dispatch(actions.setSellsy(token, sellsyId, sellsyBody))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contact)
