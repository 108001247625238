import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    product: [],
    loading: false
}
const fetchProductStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchProductSuccess = (state, action) => {
    return updateObject(state, {
        product: action.product,
        loading: false
    });
}
const fecthProductFail = (state, action) => {
    return updateObject(state, { loading: false });
}
const setProductStart = (state, action) => {
    return updateObject(state, {loading: true});
};
const setProductSuccess = (state, action) => {
    return updateObject(state, {
        products: action.products,
        loading: false
    });
};
const setProductFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const reducerProd = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PRODUCT_START: return fetchProductStart(state, action);
        case actionTypes.FETCH_PRODUCT_SUCCESS: return fetchProductSuccess(state, action);
        case actionTypes.FETCH_PRODUCT_FAIL: return fecthProductFail(state, action);
        case actionTypes.SET_PRODUCT_START: return setProductStart(state, action);
        case actionTypes.SET_PRODUCT_SUCCESS: return setProductSuccess(state, action);
        case actionTypes.SET_PRODUCT_FAIL: return setProductFail(state, action);
        default: return state;
    }
}
export default reducerProd;
