import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
class Detail extends Component {
  render(props) {
    let details = null;
    if (!this.props.loading) {
      details = (
        <div>
          <br></br><br></br>
          <div class="card text-center">
            <div class="card-header">
                <strong>{this.props.singleDetail.nom}  </strong>
                <strong>{this.props.singleDetail.prenom}</strong>
            </div>
            <div class="card-body">
            Nom complet : <h5 class="card-title">  {this.props.singleDetail.fullname}</h5>
              <p class="card-text"> Email : {this.props.singleDetail.email}</p>
              <p class="card-text"> Fonction :{this.props.singleDetail.fonction}</p>
              <p class="card-text"> Entreprise :{this.props.singleDetail.contactentreprise.nomentreprise}</p>
            </div>
            <div class="card-footer text-muted">
             ________________________
            </div>
          </div>
          <br></br>
          <center>
          <a href="/contact" class="btn btn-primary"> Retour... </a>
          </center>
        </div>
      );
    }
    return (
      <div>
        {details}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    detail: state.details.detail,
    loading: state.details.loading,
    singleDetail: state.details.singleDetail,
    token: state.auth.token,
    totaC: state.details.totalcount
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDetail: (token) => dispatch(actions.fetchDetail(token))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Detail)
