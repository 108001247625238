
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
export const SET_USER_START = 'SET_USER_START';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_FAIL = 'SET_USER_FAIL';
export const FETCH_DEVIS_START = 'FETCH_DEVIS_START';
export const FETCH_DEVIS_SUCCESS = 'FETCH_DEVIS_SUCCESS';
export const FETCH_DEVIS_FAIL = 'FETCH_DEVIS_FAIL';
export const SET_DEVIS_START = 'SET_DEVIS_START';
export const SET_DEVIS_SUCCESS = 'SET_DEVIS_SUCCESS';
export const SET_DEVIS_FAIL = 'SET_DEVIS_FAIL'
export const FETCH_CONTACT_START = 'FETCH_CONTACT_START';
export const FETCH_CONTACT_SUCCESS = 'FETCH_CONTACT_SUCCESS';
export const FETCH_CONTACT_FAIL = 'FETCH_CONTACT_FAIL';
export const SET_CONTACT_START = 'SET_CONTACT_START';
export const SET_CONTACT_SUCCESS = 'SET_CONTACT_SUCCESS';
export const SET_CONTACT_FAIL = 'SET_CONTACT_FAIL'
export const FETCH_PRODUCT_START = 'FETCH_PRODUCT_START';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAIL = 'FETCH_PRODUCT_FAIL';
export const SET_PRODUCT_START = 'SET_PRODUCT_START';
export const SET_PRODUCT_SUCCESS = 'SET_PRODUCT_SUCCESS';
export const SET_PRODUCT_FAIL = 'SET_PRODUCT_FAIL'
export const FETCH_DETAIL_START = 'FETCH_DETAIL_START';
export const FETCH_DETAIL_SUCCESS = 'FETCH_DETAIL_SUCCESS';
export const FETCH_DETAIL_FAIL = 'FETCH_DETAIL_FAIL';
export const FETCH_ENTREPRISE_START = 'FETCH_ENTREPRISE_START';
export const FETCH_ENTREPRISE_SUCCESS = 'FETCH_ENTREPRISE_SUCCESS';
export const FETCH_ENTREPRISE_FAIL = 'FETCH_ENTREPRISE_FAIL';
export const FETCH_DEMANDES_START = 'FETCH_DEMANDES_START';
export const FETCH_DEMANDES_SUCCESS = 'FETCH_DEMANDES_SUCCESS';
export const FETCH_DEMANDES_FAIL = 'FETCH_DEMANDES_FAIL';
export const ADD_DEMANDE_START = 'ADD_DEMANDE_START';
export const ADD_DEMANDE_SUCCESS = 'ADD_DEMANDE_SUCCESS';
export const ADD_DEMANDE_FAIL = 'ADD_DEMANDE_FAIL';
export const SET_DEMANDE_START = 'SET_DEMANDE_START';
export const SET_DEMANDE_SUCCESS = 'SET_DEMANDE_SUCCESS';
export const SET_DEMANDE_FAIL = 'SET_DEMANDE_FAIL'
export const FETCH_FACTURECLIENT_START = 'FETCH_FACTURECLIENT_START';
export const FETCH_FACTURECLIENT_SUCCESS = 'FETCH_FACTURECLIENT_SUCCESS';
export const FETCH_FACTURECLIENT_FAIL = 'FETCH_FACTURECLIENT_FAIL';
export const ADD_FACTURECLIENT_START = 'ADD_FACTURECLIENT_START';
export const ADD_FACTURECLIENT_SUCCESS = 'ADD_FACTURECLIENT_SUCCESS';
export const ADD_FACTURECLIENT_FAIL = 'ADD_FACTURECLIENT_FAIL';
export const SET_FACTURECLIENT_START = 'SET_FACTURECLIENT_START';
export const SET_FACTURECLIENT_SUCCESS = 'SET_FACTURECLIENT_SUCCESS';
export const SET_FACTURECLIENT_FAIL = 'SET_FACTURECLIENT_FAIL'
export const FETCH_OBJECTIF_START = 'FETCH_OBJECTIF_START';
export const FETCH_OBJECTIF_SUCCESS = 'FETCH_OBJECTIF_SUCCESS';
export const FETCH_OBJECTIF_FAIL = 'FETCH_OBJECTIF_FAIL';
export const ADD_OBJECTIF_START = 'ADD_OBJECTIF_START';
export const ADD_OBJECTIF_SUCCESS = 'ADD_OBJECTIF_SUCCESS';
export const ADD_OBJECTIF_FAIL = 'ADD_OBJECTIF_FAIL';
export const FETCH_SMART_START = 'FETCH_SMART_START';
export const FETCH_SMART_SUCCESS = 'FETCH_SMART_SUCCESS';
export const FETCH_SMART_FAIL = 'FETCH_SMART_FAIL';
export const SET_SELLSY_START = 'SET_SELLSY_START';
export const SET_SELLSY_SUCCESS = 'SET_SELLSY_SUCCESS';
export const SET_SELLSY_FAIL = 'SET_SELLSY_FAIL'
export const FORGETPWD_START = 'FORGETPWD_START';
export const FORGETPWD_SUCCESS = 'FORGETPWD_SUCCESS';
export const FORGETPWD_FAIL = 'FORGETPWD_FAIL'
export const NEWPWD_START   = 'NEWPWD_START';
export const NEWPWD_SUCCESS = 'NEWPWD_SUCCESS';
export const NEWPWD_FAIL    = 'NEWPWD_FAIL';
export const FETCH_STAFF_START = 'FETCH_STAFF_START';
export const FETCH_STAFF_SUCCESS = 'FETCH_STAFF_SUCCESS';
export const FETCH_STAFF_FAIL = 'FETCH_STAFF_FAIL';
