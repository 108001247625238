import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateObject } from '../../shared/utility';
import * as actions from '../../store/actions/index';
class Inscription extends Component {
    handleAnnuler() {
        this.props.history.push('/inscription');
    }
    state = {
        societe: {
            infogennomsocName: '',
            infogenrefName: '',
            infogensitewebName: '',
            infogencategorietarifName: '',
            coordsoctelName: '',
            coordsocmobileName: '',
            coordsocfaxName: '',
            coordsocemailName: '',
            contactpciviliteName: '',
            contactpprenomName: '',
            contactpnomName: '',
            contactpfonctionName: '',
            contactpemailName: '',
            contactptelName: '',
            contactpmobileName: '',
            contactpfaxName: '',
            addresspnomadressName: '',
            addressppartie1Name: '',
            addressppartie2Name: '',
            addressppartie3Name: '',
            addressppartie4Name: '',
            addressppaysName: '',
            addresspcodepostalName: '',
            addresspvilleName: '',
            autreinfformejuridiqueName: '',
            autreinfsiretName: '',
            autreinfsirenName: '',
            autreinfcodenafName: '',
            autreinfcapitalsocialName: '',
            autreinfrcsName: '',
            autreinftvaName: '',
            socialtwitterName: '',
            socialfaceName: '',
            sociallinkedinName: '',
            socialviadeoName: '',
            noteName: '',
        }
    }
    inputChangeHandler = (event) => {
        const { name, value } = event.target;
        const updatedElements = updateObject(this.state.societe, {
            [name]: value
        });
        this.setState({ societe: updatedElements });
    }
    submitHandler = (event) => {
        event.preventDefault();
        const { infogennomsocName, infogenrefName, infogensitewebName, infogencategorietarifName,
            coordsoctelName, coordsocmobileName, coordsocfaxName, coordsocemailName, contactpciviliteName,
            contactpprenomName,
            contactpnomName,
            contactpfonctionName,
            contactpemailName,
            contactptelName,
            contactpmobileName,
            contactpfaxName,
            addresspnomadressName,
            addressppartie1Name,
            addressppartie2Name,
            addressppartie3Name,
            addressppartie4Name,
            addressppaysName,
            addresspcodepostalName,
            addresspvilleName,
            autreinfformejuridiqueName,
            autreinfsiretName,
            autreinfsirenName,
            autreinfcodenafName,
            autreinfcapitalsocialName,
            autreinfrcsName,
            autreinftvaName,
            socialtwitterName,
            socialfaceName,
            sociallinkedinName,
            socialviadeoName,
            noteName } = this.state.societe;
        const demandeBody = {
            infogennomsoc: infogennomsocName,
            infogenref: infogenrefName,
            infogensiteweb: infogensitewebName,
            infogencategorietarif: infogencategorietarifName,
            coordsoctel: coordsoctelName,
            coordsocmobile: coordsocmobileName,
            coordsocfax: coordsocfaxName,
            coordsocemail: coordsocemailName,
            contactpcivilite: contactpciviliteName,
            contactpprenom: contactpprenomName,
            contactpnom: contactpnomName,
            contactpfonction: contactpfonctionName,
            contactpemail: contactpemailName,
            contactptel: contactptelName,
            contactpmobile: contactpmobileName,
            contactpfax: contactpfaxName,
            addresspnomadress: addresspnomadressName,
            addressppartie1: addressppartie1Name,
            addressppartie2: addressppartie2Name,
            addressppartie3: addressppartie3Name,
            addressppartie4: addressppartie4Name,
            addressppays: addressppaysName,
            addresspcodepostal: addresspcodepostalName,
            addresspville: addresspvilleName,
            autreinfformejuridique: autreinfformejuridiqueName,
            autreinfsiret: autreinfsiretName,
            autreinfsiren: autreinfsirenName,
            autreinfcodenaf: autreinfcodenafName,
            autreinfcapitalsocial: autreinfcapitalsocialName,
            autreinfrcs: autreinfrcsName,
            autreinftva: autreinftvaName,
            socialtwitter: socialtwitterName,
            socialface: socialfaceName,
            sociallinkedin: sociallinkedinName,
            socialviadeo: socialviadeoName,
            note: noteName,
        }
        this.props.onAddDemande(demandeBody, this.props.demandes);
        this.props.history.push('/success');
    }
    render() {
        return (
            <div >
                <br></br>
                <center><h1> Bienvenue chez CosaVostra </h1></center>
                <hr class="my-5"></hr>
                <form onSubmit={this.submitHandler} class="form">
                    <div class="form-row" className="d-flex justify-content-center" >
                        <div class="form-group col-md-6" >
                            <fieldset class="col-md-10" >
                                <legend class="text-left">Informations générales</legend>
                                <div>
                                    <div class="col-auto my-1">
                                        <label > Nom de la société  </label>
                                        <input name="infogennomsocName" type="text" placeholder="Entrez le nom de la société" className="form-control"
                                            onChange={(event) => this.inputChangeHandler(event)} />
                                    </div>
                                    <div class="col-auto my-1">
                                        <label> Réference</label>
                                        <input name="infogenrefName" type="text" className="form-control" placeholder="Entrez votre réference ici"
                                            onChange={(event) => this.inputChangeHandler(event)} />
                                    </div>
                                    <div class="col-auto my-1">
                                        <label class="mr-sm-2" for="inlineFormCustomSelect"> Site web</label>
                                        <input name="infogensitewebName" type="text" className="form-control" placeholder="Entrez votre siteweb ici"
                                            onChange={(event) => this.inputChangeHandler(event)} />
                                    </div>
                                    <div class="col-auto my-1">
                                        <label class="mr-sm-2" for="inlineFormCustomSelect">Catégorie tarifaire</label>
                                        <select class="custom-select mr-sm-2" id="inlineFormCustomSelect"
                                        onChange={(event) => this.inputChangeHandler(event)} name="infogencategorietarifName" >
                                            <option selected>Choisir...</option>
                                            <option value="Tarif HT" >Tarif HT </option>
                                            <option value="Tarif TTC" >Tarif TTC</option>
                                        </select>
                                    </div>
                                </div>
                            </fieldset>
                            <br></br>
                            <fieldset class="col-md-10">
                                <legend> Coordonnées société</legend>
                                <div class="col-auto my-1">
                                    <label> Tél</label>
                                    <input name="coordsoctelName" type="number" className="form-control" placeholder="Entrez votre tél ici"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Mobile</label>
                                    <input name="coordsocmobileName" type="number" className="form-control" placeholder="Entrez votre mobile ici"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Fax</label>
                                    <input name="coordsocfaxName" type="number" className="form-control" placeholder="Entrez votre fax ici"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Email</label>
                                    <input name="coordsocemailName" type="email" className="form-control" placeholder="Entrez votre email ici"
                                        onChange={(event) => this.inputChangeHandler(event)}/>
                                </div>
                            </fieldset>
                            <br></br>
                            <fieldset class="col-md-10">
                                <legend> Contact principal</legend>
                                <div class="col-auto my-1">
                                    <label> Civilité</label>
                                    <div className="radio">
                                        <div class="form-label label-auto">
                                            <label for="thirdcontact_civil_man" >
                                                <input type="radio" value="M" name="contactpciviliteName" onChange={(event) => this.inputChangeHandler(event)} />
                                                 M
                                        </label>
                                        </div>
                                        <div >
                                            <label for="thirdcontact_civil_man">
                                                <input type="radio" value="Mme" name="contactpciviliteName" onChange={(event) => this.inputChangeHandler(event)} />
                                                    Mme
                                            </label>
                                        </div>
                                        <div class="form-label label-auto">
                                            <label for="thirdcontact_civil_man">
                                                <input type="radio" value="Mlle" name="contactpciviliteName" onChange={(event) => this.inputChangeHandler(event)} />
                                                   Mlle
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-auto my-1">
                                    <label> Prénom *</label>
                                    <input name="contactpprenomName" type="text" className="form-control" placeholder="Entrez votre prénom ici"
                                        onChange={(event) => this.inputChangeHandler(event)} required />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Nom *</label>
                                    <input name="contactpnomName" type="text" className="form-control" placeholder="Entrez votre nom ici"
                                        onChange={(event) => this.inputChangeHandler(event)} required />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Fonction *</label>
                                    <input name="contactpfonctionName" type="text" className="form-control" placeholder="Entrez votre fonction ici"
                                        onChange={(event) => this.inputChangeHandler(event)} required />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Email *</label>
                                    <input name="contactpemailName" type="email" className="form-control" placeholder="Entrez votre email ici"
                                        onChange={(event) => this.inputChangeHandler(event)} required />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Tél *</label>
                                    <input name="contactptelName" type="number" className="form-control" placeholder="Entrez votre tél ici"
                                        onChange={(event) => this.inputChangeHandler(event)} required />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Mobile *</label>
                                    <input name="contactpmobileName" type="number" className="form-control" placeholder="Entrez votre mobile ici"
                                        onChange={(event) => this.inputChangeHandler(event)} required />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Fax </label>
                                    <input name="contactpfaxName" type="number" className="form-control" placeholder="Entrez votre fax ici"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                            </fieldset>
                            <br></br>
                            <fieldset class="col-md-10">
                                <legend > Adresse de facturation</legend>
                                <div class="col-auto my-1">
                                    <label> Nom de l’adresse *</label>
                                    <input name="addresspnomadressName" type="text" className="form-control" placeholder="Entrez votre nom de l’adresse ici"
                                        onChange={(event) => this.inputChangeHandler(event)} required />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Partie 1 *</label>
                                    <input name="addressppartie1Name" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} required />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Partie 2 *</label>
                                    <input name="addressppartie2Name" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Partie 3 *</label>
                                    <input name="addressppartie3Name" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Partie 4 *</label>
                                    <input name="addressppartie4Name" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Pays * </label>
                                    <select class="custom-select mr-sm-2" id="inlineFormCustomSelect" name="addressppaysName"
                                    onChange={(event) => this.inputChangeHandler(event)} required>
                                        <option value="Belgique" euro="Y" data-hasprovince="Y">Belgique</option>
                                        <option value="Tunisie" euro="N" data-hasprovince="Y">Tunisie</option>
                                        <option value="Canada" euro="N" data-hasprovince="Y">Canada</option>
                                        <option value="Suisse" euro="Y" data-hasprovince="Y">Suisse</option>
                                        <option value="Allemagne" euro="Y" data-hasprovince="Y">Allemagne</option>
                                        <option value="Espagne" euro="Y" data-hasprovince="Y">Espagne</option>
                                        <option value="France" euro="Y" data-hasprovince="N" >France </option>
                                        <option value="Royaume-Uni" euro="Y" data-hasprovince="Y">Royaume-Uni</option>
                                        <option value="Italie" euro="Y" data-hasprovince="Y">Italie</option>
                                        <option value="Luxembourg" euro="Y" data-hasprovince="Y">Luxembourg</option>
                                        <option value="Saint-Martin (FR)" euro="N" data-hasprovince="Y">Saint-Martin (FR)</option>
                                        <option value="Pays-Bas" euro="Y" data-hasprovince="Y">Pays-Bas</option>
                                        <option value="Portugal" euro="Y" data-hasprovince="Y">Portugal</option>
                                        <option value="Saint-Marin" euro="N" data-hasprovince="Y">Saint-Marin</option>
                                        <option value="Sint Maarten" euro="N" data-hasprovince="Y">Sint Maarten</option>
                                        <option value="US" euro="N" data-hasprovince="Y">États-Unis</option>
                                        <option value="" euro="N" data-hasprovince="Y" selected="true">—————</option>
                                        <option value="Andorre" euro="N" data-hasprovince="Y">Andorre</option>
                                        <option value="Émirats Arabes Unis" euro="N" data-hasprovince="Y">Émirats Arabes Unis</option>
                                        <option value="Afghanistan" euro="N" data-hasprovince="Y">Afghanistan</option>
                                        <option value="Antigua-Et-Barbuda" euro="N" data-hasprovince="Y">Antigua-Et-Barbuda</option>
                                        <option value="Anguilla" euro="N" data-hasprovince="Y">Anguilla</option>
                                        <option value="Albanie" euro="Y" data-hasprovince="Y">Albanie</option>
                                        <option value="Arménie" euro="N" data-hasprovince="Y">Arménie</option>
                                        <option value="Antilles néerlandaises" euro="N" data-hasprovince="Y">Antilles néerlandaises</option>
                                        <option value="Angola" euro="N" data-hasprovince="Y">Angola</option>
                                        <option value="Antarctique" euro="N" data-hasprovince="Y">Antarctique</option>
                                        <option value="Argentine" euro="N" data-hasprovince="Y">Argentine</option>
                                        <option value="Samoa américaines" euro="N" data-hasprovince="Y">Samoa américaines</option>
                                        <option value="Autriche" euro="Y" data-hasprovince="Y">Autriche</option>
                                        <option value="Australie" euro="N" data-hasprovince="Y">Australie</option>
                                        <option value="Aruba" euro="N" data-hasprovince="Y">Aruba</option>
                                        <option value="Azerbaïdjan" euro="N" data-hasprovince="Y">Azerbaïdjan</option>
                                        <option value="Bosnie-Herzégovine" euro="Y" data-hasprovince="Y">Bosnie-Herzégovine</option>
                                        <option value="Barbade" euro="N" data-hasprovince="Y">Barbade</option>
                                        <option value="Bangladesh" euro="N" data-hasprovince="Y">Bangladesh</option>
                                        <option value="Burkina faso" euro="N" data-hasprovince="Y">Burkina faso</option>
                                        <option value="Bulgarie" euro="Y" data-hasprovince="Y">Bulgarie</option>
                                        <option value="Bahreïn" euro="N" data-hasprovince="Y">Bahreïn</option>
                                        <option value="Burundi" euro="N" data-hasprovince="Y">Burundi</option>
                                        <option value="Bénin" euro="N" data-hasprovince="Y">Bénin</option>
                                        <option value="Saint-Barthélemy" euro="Y" data-hasprovince="Y">Saint-Barthélemy</option>
                                        <option value="Bermudes" euro="N" data-hasprovince="Y">Bermudes</option>
                                        <option value="Brunéi darussalam" euro="N" data-hasprovince="Y">Brunéi darussalam</option>
                                        <option value="Bolivie, l'état plurinational de" euro="N" data-hasprovince="Y">Bolivie, l'état plurinational de</option>
                                        <option value="Brésil" euro="N" data-hasprovince="Y">Brésil</option>
                                        <option value="Bahamas" euro="N" data-hasprovince="Y">Bahamas</option>
                                        <option value="Bhoutan" euro="N" data-hasprovince="Y">Bhoutan</option>
                                        <option value="Bouvet, île" euro="N" data-hasprovince="Y">Bouvet, île</option>
                                        <option value="Botswana" euro="N" data-hasprovince="Y">Botswana</option>
                                        <option value="Bélarus" euro="Y" data-hasprovince="Y">Bélarus</option>
                                        <option value="Belize" euro="N" data-hasprovince="Y">Belize</option>
                                        <option value="Cocos (keeling), îles" euro="N" data-hasprovince="Y">Cocos (keeling), îles</option>
                                        <option value="Congo, la république démocratique du" euro="N" data-hasprovince="Y">Congo, la république démocratique du</option>
                                        <option value="Centrafricaine, république" euro="N" data-hasprovince="Y">Centrafricaine, république</option>
                                        <option value="Congo" euro="N" data-hasprovince="Y">Congo</option>
                                        <option value="Côte d'ivoire" euro="N" data-hasprovince="Y">Côte d'ivoire</option>
                                        <option value="Cook, îles" euro="N" data-hasprovince="Y">Cook, îles</option>
                                        <option value="Chili" euro="N" data-hasprovince="Y">Chili</option>
                                        <option value="Cameroun" euro="N" data-hasprovince="Y">Cameroun</option>
                                        <option value="Chine" euro="N" data-hasprovince="Y">Chine</option>
                                        <option value="Colombie" euro="N" data-hasprovince="Y">Colombie</option>
                                        <option value="Costa rica" euro="N" data-hasprovince="Y">Costa rica</option>
                                        <option value="Cuba" euro="N" data-hasprovince="Y">Cuba</option>
                                        <option value="Cap-Vert" euro="N" data-hasprovince="Y">Cap-Vert</option>
                                        <option value="Christmas, île" euro="N" data-hasprovince="Y">Christmas, île</option>
                                        <option value="Chypre" euro="Y" data-hasprovince="Y">Chypre</option>
                                        <option value="Tchèque, république" euro="Y" data-hasprovince="Y">Tchèque, république</option>
                                        <option value="Djibouti" euro="N" data-hasprovince="Y">Djibouti</option>
                                        <option value="Danemark" euro="Y" data-hasprovince="Y">Danemark</option><option value="DM" euro="N" data-hasprovince="Y">Dominique</option>
                                        <option value="Dominicaine, république" euro="N" data-hasprovince="Y">Dominicaine, république</option>
                                        <option value="Algérie" euro="N" data-hasprovince="Y">Algérie</option>
                                        <option value="Équateur" euro="N" data-hasprovince="Y">Équateur</option>
                                        <option value="Estonie" euro="Y" data-hasprovince="Y">Estonie</option>
                                        <option value="Égypte" euro="N" data-hasprovince="Y">Égypte</option>
                                        <option value="Sahara occidental" euro="N" data-hasprovince="Y">Sahara occidental</option>
                                        <option value="Érythrée" euro="N" data-hasprovince="Y">Érythrée</option>
                                        <option value="Éthiopie" euro="N" data-hasprovince="Y">Éthiopie</option>
                                        <option value="Finlande" euro="Y" data-hasprovince="Y">Finlande</option>
                                        <option value="Fidji" euro="N" data-hasprovince="Y">Fidji</option>
                                        <option value="Falkland, îles (malvinas)" euro="N" data-hasprovince="Y">Falkland, îles (malvinas)</option>
                                        <option value="Micronésie, états fédérés de" euro="N" data-hasprovince="Y">Micronésie, états fédérés de</option>
                                        <option value="Féroé, îles" euro="N" data-hasprovince="Y">Féroé, îles</option>
                                        <option value="Gabon" euro="N" data-hasprovince="Y">Gabon</option>
                                        <option value="Grenade" euro="N" data-hasprovince="Y">Grenade</option>
                                        <option value="Géorgie" euro="Y" data-hasprovince="Y">Géorgie</option>
                                        <option value="Guyane française" euro="Y" data-hasprovince="Y">Guyane française</option>
                                        <option value="Guernesey" euro="N" data-hasprovince="Y">Guernesey</option>
                                        <option value="Ghana" euro="N" data-hasprovince="Y">Ghana</option>
                                        <option value="Gibraltar" euro="Y" data-hasprovince="Y">Gibraltar</option>
                                        <option value="Groenland" euro="N" data-hasprovince="Y">Groenland</option>
                                        <option value="Gambie" euro="N" data-hasprovince="Y">Gambie</option>
                                        <option value="Guinée" euro="N" data-hasprovince="Y">Guinée</option>
                                        <option value="Guadeloupe" euro="Y" data-hasprovince="Y">Guadeloupe</option>
                                        <option value="Guinée équatoriale" euro="N" data-hasprovince="Y">Guinée équatoriale</option>
                                        <option value="Grèce" euro="Y" data-hasprovince="Y">Grèce</option>
                                        <option value="Géorgie du sud et les îles sandwich du sud" euro="N" data-hasprovince="Y">Géorgie du sud et les îles sandwich du sud</option>
                                        <option value="Guatemala" euro="N" data-hasprovince="Y">Guatemala</option>
                                        <option value="Guam" euro="N" data-hasprovince="Y">Guam</option>
                                        <option value="Guinée-Bissau" euro="N" data-hasprovince="Y">Guinée-Bissau</option>
                                        <option value="Guyana" euro="N" data-hasprovince="Y">Guyana</option>
                                        <option value="Hong-Kong" euro="N" data-hasprovince="Y">Hong-Kong</option>
                                        <option value="Heard, île et mcdonald, îles" euro="N" data-hasprovince="Y">Heard, île et mcdonald, îles</option>
                                        <option value="Honduras" euro="N" data-hasprovince="Y">Honduras</option>
                                        <option value="Croatie" euro="Y" data-hasprovince="Y">Croatie</option>
                                        <option value="Haïti" euro="N" data-hasprovince="Y">Haïti</option>
                                        <option value="Hongrie" euro="Y" data-hasprovince="Y">Hongrie</option>
                                        <option value="Indonésie" euro="N" data-hasprovince="Y">Indonésie</option>
                                        <option value="Irlande" euro="Y" data-hasprovince="Y">Irlande</option>
                                        <option value="Palestine" euro="N" data-hasprovince="Y">Palestine</option>
                                        <option value="île de man" euro="N" data-hasprovince="Y">île de man</option>
                                        <option value="Inde" euro="N" data-hasprovince="Y">Inde</option>
                                        <option value="Océan indien, territoire britannique de l'" euro="N" data-hasprovince="Y">Océan indien, territoire britannique de l'</option>
                                        <option value="Iraq" euro="N" data-hasprovince="Y">Iraq</option>
                                        <option value="Iran, république islamique" euro="N" data-hasprovince="Y">Iran, république islamique </option>
                                        <option value="Islande" euro="Y" data-hasprovince="Y">Islande</option>
                                        <option value="Jersey" euro="N" data-hasprovince="Y">Jersey</option>
                                        <option value="Jamaïque" euro="N" data-hasprovince="Y">Jamaïque</option>
                                        <option value="Jordanie" euro="N" data-hasprovince="Y">Jordanie</option>
                                        <option value="Japon" euro="N" data-hasprovince="Y">Japon</option>
                                        <option value="Kenya" euro="N" data-hasprovince="Y">Kenya</option>
                                        <option value="Kirghizistan" euro="N" data-hasprovince="Y">Kirghizistan</option>
                                        <option value="Cambodge" euro="N" data-hasprovince="Y">Cambodge</option>
                                        <option value="Kiribati" euro="N" data-hasprovince="Y">Kiribati</option>
                                        <option value="Comores" euro="N" data-hasprovince="Y">Comores</option>
                                        <option value="Saint-Kitts-Et-Nevis" euro="N" data-hasprovince="Y">Saint-Kitts-Et-Nevis</option>
                                        <option value="République populaire démocratique de Corée" euro="N" data-hasprovince="Y">République populaire démocratique de Corée</option>
                                        <option value="République de Corée" euro="N" data-hasprovince="Y">République de Corée</option>
                                        <option value="Koweït" euro="N" data-hasprovince="Y">Koweït</option>
                                        <option value="Caïmanes, îles" euro="N" data-hasprovince="Y">Caïmanes, îles</option>
                                        <option value="Kazakhstan" euro="N" data-hasprovince="Y">Kazakhstan</option>
                                        <option value="Lao, république démocratique populaire" euro="N" data-hasprovince="Y">Lao, république démocratique populaire</option>
                                        <option value="Liban" euro="N" data-hasprovince="Y">Liban</option>
                                        <option value="Sainte-Lucie" euro="N" data-hasprovince="Y">Sainte-Lucie</option>
                                        <option value="Liechtenstein" euro="Y" data-hasprovince="Y">Liechtenstein</option>
                                        <option value="Sri lanka" euro="N" data-hasprovince="Y">Sri lanka</option>
                                        <option value="Libéria" euro="N" data-hasprovince="Y">Libéria</option>
                                        <option value="Lesotho" euro="N" data-hasprovince="Y">Lesotho</option>
                                        <option value="Lituanie" euro="Y" data-hasprovince="Y">Lituanie</option>
                                        <option value="Lettonie" euro="Y" data-hasprovince="Y">Lettonie</option>
                                        <option value="Libye" euro="N" data-hasprovince="Y">Libye</option>
                                        <option value="Maroc" euro="N" data-hasprovince="Y">Maroc</option>
                                        <option value="Monaco" euro="N" data-hasprovince="Y">Monaco</option>
                                        <option value="Moldova" euro="N" data-hasprovince="Y">Moldova</option>
                                        <option value="Monténégro" euro="N" data-hasprovince="Y">Monténégro</option>
                                        <option value="Madagascar" euro="N" data-hasprovince="Y">Madagascar</option>
                                        <option value="Marshall, îles" euro="N" data-hasprovince="Y">Marshall, îles</option>
                                        <option value="Macédoine du Nord" euro="Y" data-hasprovince="Y">Macédoine du Nord</option>
                                        <option value="Mali" euro="N" data-hasprovince="Y">Mali</option>
                                        <option value="Myanmar" euro="N" data-hasprovince="Y">Myanmar</option>
                                        <option value="Mongolie" euro="N" data-hasprovince="Y">Mongolie</option>
                                        <option value="Macao" euro="N" data-hasprovince="Y">Macao</option>
                                        <option value="Mariannes du nord, îles" euro="N" data-hasprovince="Y">Mariannes du nord, îles</option>
                                        <option value="Martinique" euro="Y" data-hasprovince="Y">Martinique</option>
                                        <option value="Mauritanie" euro="N" data-hasprovince="Y">Mauritanie</option>
                                        <option value="Montserrat" euro="N" data-hasprovince="Y">Montserrat</option>
                                        <option value="Malte" euro="Y" data-hasprovince="Y">Malte</option>
                                        <option value="Maurice" euro="N" data-hasprovince="Y">Maurice</option>
                                        <option value="Maldives" euro="N" data-hasprovince="Y">Maldives</option>
                                        <option value="Malawi" euro="N" data-hasprovince="Y">Malawi</option>
                                        <option value="Mexique" euro="N" data-hasprovince="Y">Mexique</option>
                                        <option value="Malaisie" euro="N" data-hasprovince="Y">Malaisie</option>
                                        <option value="Mozambique" euro="N" data-hasprovince="Y">Mozambique</option>
                                        <option value="Namibie" euro="N" data-hasprovince="Y">Namibie</option>
                                        <option value="Nouvelle-Calédonie" euro="N" data-hasprovince="Y">Nouvelle-Calédonie</option>
                                        <option value="Niger" euro="N" data-hasprovince="Y">Niger</option>
                                        <option value="Norfolk, île" euro="N" data-hasprovince="Y">Norfolk, île</option>
                                        <option value="Nigéria" euro="N" data-hasprovince="Y">Nigéria</option>
                                        <option value="Nicaragua" euro="N" data-hasprovince="Y">Nicaragua</option>
                                        <option value="Norvège" euro="Y" data-hasprovince="Y">Norvège</option>
                                        <option value="Népal" euro="N" data-hasprovince="Y">Népal</option>
                                        <option value="Nauru" euro="N" data-hasprovince="Y">Nauru</option>
                                        <option value="Niué" euro="N" data-hasprovince="Y">Niué</option>
                                        <option value="Nouvelle-Zélande" euro="N" data-hasprovince="Y">Nouvelle-Zélande</option>
                                        <option value="Oman" euro="N" data-hasprovince="Y">Oman</option>
                                        <option value="Panama" euro="N" data-hasprovince="Y">Panama</option>
                                        <option value="Pérou" euro="N" data-hasprovince="Y">Pérou</option>
                                        <option value="Polynésie française" euro="N" data-hasprovince="Y">Polynésie française</option>
                                        <option value="Papouasie-Nouvelle-Guinée" euro="N" data-hasprovince="Y">Papouasie-Nouvelle-Guinée</option>
                                        <option value="Philippines" euro="N" data-hasprovince="Y">Philippines</option>
                                        <option value="Pakistan" euro="N" data-hasprovince="Y">Pakistan</option>
                                        <option value="Pologne" euro="Y" data-hasprovince="Y">Pologne</option>
                                        <option value="Saint-Pierre-Et-Miquelon" euro="N" data-hasprovince="Y">Saint-Pierre-Et-Miquelon</option>
                                        <option value="Pitcairn" euro="N" data-hasprovince="Y">Pitcairn</option>
                                        <option value="Porto rico" euro="N" data-hasprovince="Y">Porto rico</option>
                                        <option value="Palestinien" euro="N" data-hasprovince="Y">Palestinien</option>
                                        <option value="Palaos" euro="N" data-hasprovince="Y">Palaos</option>
                                        <option value="Paraguay" euro="N" data-hasprovince="Y">Paraguay</option>
                                        <option value="Qatar" euro="N" data-hasprovince="Y">Qatar</option>
                                        <option value="Réunion" euro="Y" data-hasprovince="Y">Réunion</option>
                                        <option value="Roumanie" euro="Y" data-hasprovince="Y">Roumanie</option>
                                        <option value="Serbie" euro="Y" data-hasprovince="Y">Serbie</option>
                                        <option value="Russie, fédération de" euro="N" data-hasprovince="Y">Russie, fédération de</option>
                                        <option value="Rwanda" euro="N" data-hasprovince="Y">Rwanda</option>
                                        <option value="Arabie saoudite" euro="N" data-hasprovince="Y">Arabie saoudite</option>
                                        <option value="Salomon, îles" euro="N" data-hasprovince="Y">Salomon, îles</option>
                                        <option value="Seychelles" euro="N" data-hasprovince="Y">Seychelles</option>
                                        <option value="Soudan" euro="N" data-hasprovince="Y">Soudan</option>
                                        <option value="Suède" euro="Y" data-hasprovince="Y">Suède</option>
                                        <option value="Singapour" euro="N" data-hasprovince="Y">Singapour</option>
                                        <option value="Sainte-Hélène, ascension et tristan da cunha"
                                        euro="N" data-hasprovince="Y">Sainte-Hélène, ascension et tristan da cunha</option>
                                        <option value="Slovénie" euro="Y" data-hasprovince="Y">Slovénie</option>
                                        <option value="Svalbard et île jan mayen" euro="N" data-hasprovince="Y">Svalbard et île jan mayen</option>
                                        <option value="Slovaquie" euro="Y" data-hasprovince="Y">Slovaquie</option>
                                        <option value="Sierra leone" euro="N" data-hasprovince="Y">Sierra leone</option>
                                        <option value="Sénégal" euro="N" data-hasprovince="Y">Sénégal</option>
                                        <option value="Somalie" euro="N" data-hasprovince="Y">Somalie</option>
                                        <option value="Suriname" euro="N" data-hasprovince="Y">Suriname</option>
                                        <option value="Sao tomé-Et-Principe" euro="N" data-hasprovince="Y">Sao tomé-Et-Principe</option>
                                        <option value="El salvador" euro="N" data-hasprovince="Y">El salvador</option>
                                        <option value="Syrie" euro="N" data-hasprovince="Y">Syrie</option>
                                        <option value="Swaziland" euro="N" data-hasprovince="Y">Swaziland</option>
                                        <option value="Turks et caïques, îles" euro="N" data-hasprovince="Y">Turks et caïques, îles</option>
                                        <option value="Tchad" euro="N" data-hasprovince="Y">Tchad</option>
                                        <option value="Terres australes françaises" euro="N" data-hasprovince="Y">Terres australes françaises</option>
                                        <option value="Togo" euro="N" data-hasprovince="Y">Togo</option>
                                        <option value="Thaïlande" euro="N" data-hasprovince="Y">Thaïlande</option>
                                        <option value="Tadjikistan" euro="N" data-hasprovince="Y">Tadjikistan</option>
                                        <option value="Tokelau" euro="N" data-hasprovince="Y">Tokelau</option>
                                        <option value="Timor-Leste" euro="N" data-hasprovince="Y">Timor-Leste</option>
                                        <option value="Turkménistan" euro="N" data-hasprovince="Y">Turkménistan</option>
                                        <option value="Tunisie" euro="N" data-hasprovince="Y">Tunisie</option>
                                        <option value="Tonga" euro="N" data-hasprovince="Y">Tonga</option>
                                        <option value="Turquie" euro="Y" data-hasprovince="Y">Turquie</option>
                                        <option value="Trinité-Et-Tobago" euro="N" data-hasprovince="Y">Trinité-Et-Tobago</option>
                                        <option value="Tuvalu" euro="N" data-hasprovince="Y">Tuvalu</option>
                                        <option value="Taïwan" euro="N" data-hasprovince="Y">Taïwan</option>
                                        <option value="Tanzanie, république-Unie de" euro="N" data-hasprovince="Y">Tanzanie, république-Unie de</option>
                                        <option value="Ukraine" euro="Y" data-hasprovince="Y">Ukraine</option>
                                        <option value="Ouganda" euro="N" data-hasprovince="Y">Ouganda</option>
                                        <option value="îles mineures éloignées des états-Unis" euro="N" data-hasprovince="Y">îles mineures éloignées des états-Unis</option>
                                        <option value="Uruguay" euro="N" data-hasprovince="Y">Uruguay</option>
                                        <option value="Ouzbékistan" euro="N" data-hasprovince="Y">Ouzbékistan</option>
                                        <option value="Saint-Siège (état de la cité du vatican)" euro="N" data-hasprovince="Y">Saint-Siège (état de la cité du vatican)</option>
                                        <option value="Saint-Vincent-Et-Les grenadines" euro="N" data-hasprovince="Y">Saint-Vincent-Et-Les grenadines</option>
                                        <option value="Venezuela, république bolivarienne du" euro="N" data-hasprovince="Y">Venezuela, république bolivarienne du</option>
                                        <option value="îles vierges britanniques" euro="N" data-hasprovince="Y">îles vierges britanniques</option>
                                        <option value="îles vierges des états-Unis" euro="N" data-hasprovince="Y">îles vierges des états-Unis</option>
                                        <option value="Viet nam" euro="N" data-hasprovince="Y">Viet nam</option>
                                        <option value="Vanuatu" euro="N" data-hasprovince="Y">Vanuatu</option>
                                        <option value="Wallis et futuna" euro="N" data-hasprovince="Y">Wallis et futuna</option>
                                        <option value="Samoa" euro="N" data-hasprovince="Y">Samoa</option>
                                        <option value="Kosovo" euro="N" data-hasprovince="Y">Kosovo</option>
                                        <option value="Yémen" euro="N" data-hasprovince="Y">Yémen</option>
                                        <option value="Mayotte" euro="Y" data-hasprovince="Y">Mayotte</option>
                                        <option value="Afrique du sud" euro="N" data-hasprovince="Y">Afrique du sud</option>
                                        <option value="Zambie" euro="N" data-hasprovince="Y">Zambie</option>
                                        <option value="Zimbabwe" euro="N" data-hasprovince="Y">Zimbabwe</option>
                                        <option value="Curaçao" euro="N" data-hasprovince="Y">Curaçao</option>
                                    </select>
                                </div>
                                <div class="col-auto my-1">
                                    <label> Code postal </label>
                                    <input name="addresspcodepostalName" type="text" className="form-control" placeholder="Entrez code postal"
                                        onChange={(event) => this.inputChangeHandler(event)}  />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Ville *</label>
                                    <input name="addresspvilleName" type="text" className="form-control" placeholder="Entrez code Ville"
                                        onChange={(event) => this.inputChangeHandler(event)} required />
                                </div>
                            </fieldset>
                        </div>
                        <br></br>
                        <div class="form-group col-md-6">
                            <fieldset class="col-md-10">
                                <legend>Autres informations</legend>
                                <div class="col-auto my-1">
                                    <label> Forme juridique </label>
                                    <input name="autreinfformejuridiqueName" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> N° Siret</label>
                                    <input name="autreinfsiretName" type="number" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> N° Siren</label>
                                    <input name="autreinfsirenName" type="number" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Code NAF (APE)</label>
                                    <input name="autreinfcodenafName" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Capital social</label>
                                    <input name="autreinfcapitalsocialName" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> RCS</label>
                                    <input name="autreinfrcsName" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">

                                    <label> N° TVA intra</label>
                                    <input name="autreinftvaName" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} required={this.state.societe.addressppaysName === 'France' ? false : true} />
                                </div>
                            </fieldset>
                            <br></br>
                            <fieldset class="col-md-10">
                                <legend> Social</legend>
                                <div class="col-auto my-1">
                                    <label> Twitter</label>
                                    <input name="socialtwitterName" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">

                                    <label> Facebook</label>
                                    <input name="socialfaceName" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> LinkedIN</label>
                                    <input name="sociallinkedinName" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                                <div class="col-auto my-1">
                                    <label> Viadeo</label>
                                    <input name="socialviadeoName" type="text" className="form-control"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                            </fieldset>
                            <br></br>
                            <fieldset class="col-md-10">
                                <legend> Note </legend>
                                <div class="col-auto my-1">
                                    <textarea name="noteName" type="text" class="form-control form-rounded" rows="5"
                                        onChange={(event) => this.inputChangeHandler(event)} />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    <center>
                        <button type="submit" className="btn btn-primary btn-lg"  > Envoyer ...  </button>
                    </center>
                </form>
            </div >
        )
    }
}
const mapStateTpProps = state => {
    return {
        demandes: state.demande.demandes,
        totaC: state.demande.totalcount
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onAddDemande: (demandeBody, demandesData) => dispatch(actions.addDemande(demandeBody, demandesData))
    };
};
export default connect(mapStateTpProps, mapDispatchToProps)(Inscription);
