import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    demandes: [],
    demande: null,
    loading: false,
    totalcount: 0
}
const fetchDemandeStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchDemandeSuccess = (state, action) => {
    return updateObject(state, {
        demandes: action.demandes,
        loading: false,
        totalcount: action.total
    });
}
const fecthDemandeFail = (state, action) => {
    return updateObject(state, { loading: false });
}
const addDemandeStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const addDemandeSuccess = (state, action) => {
    return updateObject(state, {
        demandes: action.demandes,
        demande: action.demande,
        loading: false,
        totalcount: action.total
    });
}
const addDemandeFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const setDemandesStart = (state, action) => {
    return updateObject(state, {loading: true});
};
const setDemandesSuccess = (state, action) => {
    return updateObject(state, {
        demandes: action.demandes,
        loading: false
    });
};
const setDemandesFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const reducerdem = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DEMANDES_START: return fetchDemandeStart(state, action);
        case actionTypes.FETCH_DEMANDES_SUCCESS: return fetchDemandeSuccess(state, action);
        case actionTypes.FETCH_DEMANDES_FAIL: return fecthDemandeFail(state, action);
        case actionTypes.ADD_DEMANDE_START: return addDemandeStart(state, action);
        case actionTypes.ADD_DEMANDE_SUCCESS: return addDemandeSuccess(state, action);
        case actionTypes.ADD_DEMANDE_FAIL: return addDemandeFail(state, action);
        case actionTypes.SET_DEMANDE_START: return setDemandesStart(state, action);
        case actionTypes.SET_DEMANDE_SUCCESS: return setDemandesSuccess(state, action);
        case actionTypes.SET_DEMANDE_FAIL: return setDemandesFail(state, action);
        default: return state;
    }
}
export default reducerdem;
