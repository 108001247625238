import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const fetchDemandeSuccess = (demandes) => {
  return {
    type: actionTypes.FETCH_DEMANDES_SUCCESS,
    demandes: demandes,
  };
};
export const fetchDemandeFail = (error) => {
  return {
    type: actionTypes.FETCH_DEMANDES_FAIL,
    error: error
  };
};
export const fetchDemandeStart = () => {
  return {
    type: actionTypes.FETCH_DEMANDES_START
  };
};
export const fetchDemande = (token) => {
  return dispatch => {
    dispatch(fetchDemandeStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.get(`${BACK_BASE_URL}/api/demandeinscriptions?status=waitting`, headerConfig)
      .then(res => {
        const fecthedDemande = [];
        for (const key in res.data['hydra:member']) {
          fecthedDemande.push({
            ...res.data['hydra:member'][key],
            counter: res.data['hydra:member'][key].id,
          });
        }
        dispatch(fetchDemandeSuccess(fecthedDemande));
      })
      .catch(err => {
        if (err.response === 401) {
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(fetchDemandeFail(err));
      })
  }
}
export const addDemandeSuccess = (demande, demandes) => {
  return {
    type: actionTypes.ADD_DEMANDE_SUCCESS,
    demandes: [...demandes],
    demande: demande
  };
};
export const addDemandeFail = (error) => {
  return {
    type: actionTypes.ADD_DEMANDE_FAIL,
    error: error
  };
};
export const addDemandeStart = () => {
  return {
    type: actionTypes.ADD_DEMANDE_START
  };
};
export const addDemande = (demandeBody, demandesData) => {
  return dispatch => {
    axios.post(`${BACK_BASE_URL}/inscription`, demandeBody)
      .then(res => {
          demandesData.push({ ...res.data });
          dispatch(addDemandeSuccess(res.data, demandesData));
          toastr.success('Demande created successfully');
      })
  }
}
export const setDemandesSuccess = (demandes) => {
  return {
    type: actionTypes.SET_DEMANDE_SUCCESS,
    demandes: [...demandes]
  };
};
export const setDemandesFail = (error) => {
  return {
    type: actionTypes.SET_DEMANDE_FAIL,
    error: error
  };
};
export const setDemandesStart = () => {
  return {
    type: actionTypes.SET_DEMANDE_START
  };
};
export const setDemandes = (token, demandeId, demandesOutsideStore) => {
  return dispatch => {
    dispatch(setDemandesStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.put(`${BACK_BASE_URL}/demande/update/${demandeId}`, headerConfig)
      .then(res => {
        if (res.status === 201) {
          const demadnesWaiting = [...demandesOutsideStore];
          const index = demadnesWaiting.findIndex((obj) => obj.id === demandeId);
          if (index > -1) {
            demadnesWaiting.splice(index, 1);
          }
          dispatch(setDemandesSuccess(demadnesWaiting));
          toastr.success('Succès', 'Le client est accepter');
        }
      })
      .catch(err => {
        if (err.response.status === 401) {
          toastr.warning(err.response.data.message, 'Please sign in again');
          dispatch(logout());
        }
        dispatch(fetchDemandeFail(err));
      })
  }
}
