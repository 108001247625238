import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import { Table } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {
    Chart,
    BarSeries,
    Title,
    ArgumentAxis,
    ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';
const data = [
    { year: 'valueField', population: 22 },

]
class Stat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data,
        };
    }
    componentDidMount() {
        this.props.onFetchFacture(this.props.token, this.state.page, this.state.itemsPerPage, this.state.search);
    }
    render() {
        const reatrdA = []
        const reatrdB = []
        const reatrdC = []
        const reatrdD = []
        let chartA = null;
        if (!this.props.loading) {
            for (const key in this.props.factures) {
                if (parseInt(this.props.factures[key].reatrdA) !== 0) {
                    reatrdA.push({
                        client: this.props.factures[key].nom,
                        valeur: parseInt(this.props.factures[key].reatrdA)
                    })
                }
                if (parseInt(this.props.factures[key].reatrdB) !== 0) {
                    reatrdB.push({
                        client: this.props.factures[key].nom,
                        valeur: parseInt(this.props.factures[key].reatrdB)
                    })
                }
                if (parseInt(this.props.factures[key].reatrdC) !== 0) {
                    reatrdC.push({
                        client: this.props.factures[key].nom,
                        valeur: parseInt(this.props.factures[key].reatrdC)
                    })
                }
                if (parseInt(this.props.factures[key].reatrdD) !== 0) {
                    reatrdD.push({
                        client: this.props.factures[key].nom,
                        valeur: parseInt(this.props.factures[key].reatrdD)
                    })
                }
            }
            chartA = (<div>
               <Table>
                    <tr>
                        <Chart
                            data={reatrdA}
                        >
                            <ArgumentAxis />
                            <ValueAxis max={10} />
                            <BarSeries
                                valueField="valeur"
                                argumentField="client"
                            />
                            <Title text="0-30Jours" />
                            <Animation />
                        </Chart></tr>
                        <tr> <Paper>
                            <Chart
                                data={reatrdB}
                            >
                                <ArgumentAxis />
                                <ValueAxis max={10} />

                                <BarSeries
                                    valueField="valeur"
                                    argumentField="client"
                                />
                                <Title text="31-60Jours" />
                                <Animation />
                            </Chart>
                        </Paper>
                    </tr>
                    <tr>
                           <Paper>
                                <Chart
                                    data={reatrdC}
                                >
                                    <ArgumentAxis />
                                    <ValueAxis max={10} />

                                    <BarSeries
                                        valueField="valeur"
                                        argumentField="client"
                                    />
                                    <Title text="61-90Jours" />
                                    <Animation />
                                </Chart>
                            </Paper>
                        </tr>
                        <tr><Paper>
                            <Chart
                                data={reatrdD}
                            >
                                <ArgumentAxis />
                                <ValueAxis max={10} />
                                <BarSeries
                                    valueField="valeur"
                                    argumentField="client"
                                />
                                <Title text="+90Jours" />

                                <Animation />
                            </Chart>
                        </Paper>
                    </tr>
                </Table></div>
            );
        }
        const facturesTableData = [];
        for (const key in this.props.factures) {
            facturesTableData.push({
                ...this.props.factures[key]
            });
        }
        return (
            <div>
                <center>
                    {chartA}
                </center>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        factures: state.facture.factures,
        loading: state.facture.loading,
        token: state.auth.token,
        totaC: state.facture.totalcount
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onFetchFacture: (token, nbPage, searchTxt) => dispatch(actions.fetchFacture(token, nbPage, searchTxt)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Stat)
