import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    smart: [],
    loading: false
}
const fetchSmartStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchSmartSuccess = (state, action) => {
    return updateObject(state, {
        smart: action.smart,
        loading: false
    });
}
const fecthSmartFail = (state, action) => {
    return updateObject(state, { loading: false });
}
const reducerSmart = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SMART_START: return fetchSmartStart(state, action);
        case actionTypes.FETCH_SMART_SUCCESS: return fetchSmartSuccess(state, action);
        case actionTypes.FETCH_SMART_FAIL: return fecthSmartFail(state, action);
        default: return state;
    }
}
export default reducerSmart;
