import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    detail: [],
    singleDetail: null,
    loading: false
}
const fetchDetailStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchDetailSuccess = (state, action) => {
    return updateObject(state, {
        singleDetail: action.singleDetail,
        loading: false
    });
}
const fecthDetailFail = (state, action) => {
    return updateObject(state, { loading: false });
}
const reducerDet = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DETAIL_START: return fetchDetailStart(state, action);
        case actionTypes.FETCH_DETAIL_SUCCESS: return fetchDetailSuccess(state, action);
        case actionTypes.FETCH_DETAIL_FAIL: return fecthDetailFail(state, action);
        default: return state;
    }
}
export default reducerDet;
