import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const fetchDevisSuccess = (devis) => {
    return {
        type: actionTypes.FETCH_DEVIS_SUCCESS,
        devis: devis
    };
};
export const fetchDevisFail = (error) => {
    return {
        type: actionTypes.FETCH_DEVIS_FAIL,
        error: error
    };
};
export const fetchDevisStart = () => {
    return {
        type: actionTypes.FETCH_DEVIS_START
    };
};
export const fetchDevis = (token) => {
    return dispatch => {
        dispatch(fetchDevisStart());
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};
        axios.get(`${BACK_BASE_URL}/ddeevviiss?isActive=true`)
            .then(res => {
            const fecthedDevis = [];
                for (const key in res.data) {
                    fecthedDevis.push({
                    ...res.data[key],
                    counter: res.data[key].id,
                    });
                }
              dispatch(fetchDevisSuccess(fecthedDevis));
            })
            .catch(err => {
                if (err.response.status === 401) {
                    toastr.warning(err.response.data.message, 'Sign in again');
                    dispatch(logout());
                }
                dispatch(fetchDevisFail(err));
            })
    }
}
export const setDevisSuccess = (devi, devis) => {
   return {
        type: actionTypes.SET_DEVIS_SUCCESS,
        devi: devi,
        devis: devis
    };
};
export const setDevisFail = (error) => {
    return {
        type: actionTypes.SET_DEVIS_FAIL,
        error: error
    };
};
export const setDevisStart = () => {
    return {
        type: actionTypes.SET_DEVIS_START
    };
};
export const setDevis = (token, devisId, updateData, devisOutsideStore) => {
    return dispatch => {
        dispatch(setDevisStart());
        const headerConfig = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.put(`${BACK_BASE_URL}/api/devis/${devisId}`, updateData, headerConfig)
            .then(res => {
                if (res.status === 200) {
                    const updatedDevis = res.data;
                    dispatch(setDevisSuccess(updatedDevis, devisOutsideStore));
                    toastr.success('Succès', 'Vos données sont bien mises à jour');
                }
            })
            .catch(err => {
                if (err.response.status === 401) {
                    toastr.warning(err.response.data.message, 'Please sign in again');
                    dispatch(logout());
                }
                dispatch(fetchDevisFail(err));
            })
    }
}
