import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';
const initialState = {
    objectifs: [],
    objectif: null,
    loading: false,
    totalcount: 0
}
const fetchObjectifStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const fetchObjectifSuccess = (state, action) => {
    return updateObject(state, {
        objectifs: action.objectifs,
        loading: false,
        totalcount: action.total
    });
}
const fecthObjectifFail = (state, action) => {
    return updateObject(state, { loading: false });
}
const addObjectifStart = (state, action) => {
    return updateObject(state, { loading: true });
}
const addObjectifSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        totalcount: action.total
    });
}
const addObjectifFail = (state, action) => {
    return updateObject(state, { loading: false })
}
const reducerobj = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_OBJECTIF_START: return fetchObjectifStart(state, action);
        case actionTypes.FETCH_OBJECTIF_SUCCESS: return fetchObjectifSuccess(state, action);
        case actionTypes.FETCH_OBJECTIF_FAIL: return fecthObjectifFail(state, action);
        case actionTypes.ADD_OBJECTIF_START: return addObjectifStart(state, action);
        case actionTypes.ADD_OBJECTIF_SUCCESS: return addObjectifSuccess(state, action);
        case actionTypes.ADD_OBJECTIF_FAIL: return addObjectifFail(state, action);
       default: return state;
    }
}
export default reducerobj;
