import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
export const fetchStaffSuccess = (staff) => {
    return {
        type: actionTypes.FETCH_STAFF_SUCCESS,
        staff: staff
    };
};
export const fecthStaffFail = (error) => {
    return {
        type: actionTypes.FETCH_STAFF_FAIL,
        error: error
    };
};
export const fetchStaffStart = () => {
    return {
        type: actionTypes.FETCH_STAFF_START
    };
};
export const fetchStaff = (token) => {
    return dispatch => {
        dispatch(fetchStaffStart());
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};
        axios.get(`${BACK_BASE_URL}/api/staffs?isActive=true`)
            .then(res => {
                const fecthedStaff = [];
               for (const key in res.data['hydra:member']) {
                fecthedStaff.push({
                    ...res.data['hydra:member'][key]
                });
            }
              dispatch(fetchStaffSuccess(fecthedStaff));
            })
            .catch(err => {
                if (err.response.status === 401) {
                    toastr.warning(err.response.data.message, 'Sign in again');
                    dispatch(logout());
                }
                dispatch(fecthStaffFail(err));
            })
    }
}
